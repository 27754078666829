<template>
  <Tabs class="iv-tab" :value="value" size="small" @on-click="tabClick">
    <TabPane :icon="item.icon || ''" :label="item.label" :name="item.name || `${index}`" v-for="(item, index) in navList" :key="index"></TabPane>
    <template slot="extra">
      <slot name="extra"></slot>
    </template>
  </Tabs>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    navList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      tabList: []
    }
  },
  watch: {
    navList: {
      handler (newVal) {
        this.tabList = newVal
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    tabClick (name) {
      this.$emit('on-click', name)
    },
    setTabName (index, name) {
      this.tabList[index].label = name
    }
  }
}
</script>

<style lang="scss">
.iv-tab {
  .ivu-tabs-bar {
    margin-bottom: 0 !important;
    .ivu-tabs-nav-right {
      margin-top: 16px;
    }
  }
  .ivu-tabs-ink-bar {
    background-color: #FFC757 !important;
  }
  .ivu-tabs-tab-active {
    font-weight: bold;
    color: rgba($color: #000000, $alpha: 0.85);
  }
  .ivu-tabs-nav .ivu-tabs-tab:hover {
    color: rgba($color: #000000, $alpha: 0.85);
  }
}
</style>
