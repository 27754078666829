<template>
  <div class="iv-block" :style="bgImg ? {'backgroundImage': `url(${bg[bgImg]})`} : ''">
    <!-- @/assets/images/new-index/2.png -->
    <header class="iv-block-title" :style="headerImg ? {'backgroundImage': `url(${headerbg[headerImg]})`} : ''">
      <span v-if="type" class="ib-border">
        <Iconfont class="iv-m-l-16 iv-left" :type="type" :style="{fontSize: size + 'px', left: left + 'px'}"></Iconfont>
      </span>
      <p class="iv-block-title-strip iv-m-l-20" v-else></p>
      <span style="width: 160px;" class="iv-fs-16 iv-fc-85 iv-fw-b iv-title" :class="`iv-m-l-${type ? 14 : 20}`">{{title}}</span>
      <img v-if="imgBorder" :style="{width: headerWidth + 'px'}" :src="`${imgBorders[imgBorder]}`" />
      <span v-if="noMore" class="iv-block-title-right iv-fs-14 iv-fc-45 iv-pointer" @click="toMorePage">
        {{moreText}}
        <iconfont class="icon iconfont icon-plus-square"></iconfont>
      </span>
      <!-- <span v-if="noMore" class="iv-block-title-right iv-m-r-20 iv-fs-14 iv-fc-45 iv-pointer icon iconfont icon-plus-square" @click="toMorePage">{{moreText}}</span> -->
    </header>
    <slot></slot>
  </div>
</template>

<script>
import Iconfont from '@/components/iconfont'

export default {
  props: {
    //判断更多显示不显示
    noMore: {
      type: Boolean,
      default: true
    },
    type: { //icon图标
      type: String,
      default: ''
    },
    size: { //icon大小  font-size  因为图标大小是不标准的
      type: Number,
      default: 28
    },
    left: {
      type: Number,
      default: -1
    },
    title: {
      type: String,
      default: '自主功能入口'
    },
    moreText: {
      type: String,
      default: '更多'
    },
    bgImg: {
      type: String,
      default: ''
    },
    headerImg: {
      type: String,
      default: ''
    },
    imgBorder: {
      type: String,
      default: ''
    },
    headerWidth: {
      type: Number,
      default: 637.5
    }
  },
  data () {
    return {
      bg: {
        'xinsheng': require('@/assets/images/new-index/2.png'),
        'diaocha': require('@/assets/images/new-index/3.png'),
        'hudong': require('@/assets/images/new-index/4.png'),
        'zhidu': require('@/assets/images/new-index/5.png')
      },
      headerbg: {
        'zizhu': require('@/assets/images/new-index/bt1.png')
      },
      imgBorders: {
        'zizhu': require('@/assets/images/new-index/ts.png'),
        'zizhu1': require('@/assets/images/new-index/tss.png')
      }
    }
  },
  mounted () {
  },
  methods: {
    toMorePage () {
      this.$emit('on-more')
    }
  },
  components: {
    Iconfont
  }
}
</script>

<style lang="scss" scoped>
.iv-block {
  padding: 0 16px 30px;
  background: #fff;
  border-radius: 8px;
  background-size: 100% 100%;
  box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.150459);
  // height: 332px;
  .iv-block-title {
    height: 55px;
    padding: 16px 16px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.14729);
    position: relative;
    margin: 0 -16px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    .iconfont {
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      left: 0px;
    }
    .iv-title {
      margin-left: 14px;
    }
    .iv-left {
      left: -1px;
      color: #fff;
    }
    .ib-border {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      background: #2373C8;
    }
    img {
      float: right;
      margin-right: 65px;
      margin-top: 3px;
      flex: 1;
      height: 20px;
    }
    .iv-block-title-strip {
      position: absolute;
      top: 53%;
      transform: translateY(-50%);
      left: 0;
      width: 6px;
      height: 20px;
      background: #2373C8;
      border-radius: 3px;
    }
    .iv-block-title-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      .iconfont {
        margin-left: 34px;
      }
    }
  }
}
</style>
