<template>
  <div>
    <div class="iv-home">
      <div class="iv-home-content iv-main-width iv-m-t-16">
        <section class="iv-home__aside">
          <UserInfo class="iv-pull-left"></UserInfo>
          <Schedule class="iv-pull-left"/>
          <!--          <HeartMailBox class="iv-pull-left"></HeartMailBox>-->
          <!--          <voiceAdvice class="iv-pull-left"></voiceAdvice>-->
          <InstitutionalIndex class="iv-pull-left"></InstitutionalIndex>
          <questionnaire class="iv-pull-left"></questionnaire>
<!--          <interactiveQuiz class="iv-pull-left"></interactiveQuiz>-->
        </section>
        <section class="iv-home__content">
          <div class="iv-home-bfc">
            <p
                class="iv-home-block iv-pull-left iv-pointer"
                @click="toDealtPage('dealtList')"
            >
              <span class="iv-border">
                <!-- <Iconfont type="daibangongzuo" class=""></Iconfont> -->
                <img
                    src="@/assets/images/new-index/daiban.png"
                    class=""
                />
              </span>
              <span class="iv-fc-65 iv-fs-16 iv-home-block-span">我的待办</span>
              <span class="iv-home-block-right iv-fs-32">{{ handleCount }}</span>
            </p>
            <p
                class="iv-home-block iv-pull-right iv-pointer"
                @click="toDealtPage('messageList')"
            >
              <span class="iv-border">
                <!-- <Iconfont type="xiaoxifabu" class=""></Iconfont> -->
                <img
                    src="@/assets/images/new-index/xiaoxi.png"
                    class=""
                />
              </span>
              <span class="iv-fc-65 iv-fs-16 iv-home-block-span">我的消息</span>
              <span class="iv-home-block-right iv-fs-32">{{ total }}</span>
            </p>
          </div>
          <FunctionBlock></FunctionBlock>
          <NewsCenter class="iv-pull-right"></NewsCenter>
          <RecommendActivities class="iv-pull-right"></RecommendActivities>
<!--          <Project class="iv-pull-right"></Project>-->
          <!--          <CourseList class="iv-pull-right"></CourseList>-->
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Project from './components/project'
import CourseList from './components/course'
import Iconfont from '@/components/iconfont'
import UserInfo from './components/user-info'
import NewsCenter from './components/news-center'
import HeartMailBox from './components/heart-mailbox'
import FunctionBlock from './components/function-block'
import InstitutionalIndex from './components/institutional-index'
import RecommendActivities from './components/recommend-activities'
import voiceAdvice from './components/voiceAdvice.vue'
import questionnaire from './components/questionnaire.vue'
import interactiveQuiz from './components/interactiveQuiz.vue'
import {newsPrefixPath} from '@/config'
import {getDateForDay} from '@/utils'
import Schedule from "@/views/index/components/schedule/Schedule.vue";

export default {
  data() {
    return {
      total: 0,
      handleCount: 0
    }
  },
  created() {
    this.getCMSName()
    this.getCMSName2()
    this.getTodoCount()
    this.getNnreadList()
    this.getElearningAreaName()
  },
  methods: {
    // 我的总消息数
    getNnreadList() {
      this.$ajax({
        url: '/api-uum/innermessage/unreadNumber/get',
        method: 'get',
        data: {
          sendDateStart: getDateForDay(6)
        }
      }).then((data) => {
        this.total = data.data.data.count
      })
    },
    // 我的待办数
    getTodoCount() {
      this.$ajax({
        url: '/api-uum/todo/getTodoCount',
        method: 'get',
      }).then((data) => {
        this.handleCount = data.data.data.handleCount
        // this.showCount = data.data.data.showCount
      })
    },
    //跳转列表
    toDealtPage(name) {
      this.$router.push({
        name: name
      })
    },
    //获取在线学习跳转域名
    getElearningAreaName() {
      this.$ajax({
        url: '/api-uum/boe/getElearningUrl',
        method: 'get',
        data: {}
      }).then(({data}) => {
        sessionStorage.setItem('elearningName', data.data)
      })
    },
    //获取文化内宣跳转域名
    getCMSName() {
      this.$ajax({
        url: `${newsPrefixPath}module/culturalPublicity/url`,
        method: 'get',
        data: {}
      }).then(({data}) => {
        sessionStorage.setItem('cmsName', data.data)
      })
    },
    getCMSName2() {
      this.$ajax({
        url: `api-uum/module/culturalPublicity/url`,
        method: 'get',
        data: {}
      }).then(({data}) => {
        sessionStorage.setItem('cmsUrl', data.data[1])
        sessionStorage.setItem('jumpCmsUrl', data.data[1])
      })
    }
  },
  components: {
    Schedule,
    Project,
    UserInfo,
    Iconfont,
    NewsCenter,
    CourseList,
    HeartMailBox,
    FunctionBlock,
    InstitutionalIndex,
    RecommendActivities,
    voiceAdvice,
    questionnaire,
    interactiveQuiz
  }
}
</script>

<style lang="scss" scoped>
.iv-home {
  .iv-home-content {
    display: flex;
    flex-direction: row;

    .iv-home__aside {
      flex-basis: 469px;
    }

    .iv-home__content {
      margin-left: 16px;
      // flex: 1;
      width: 955px;
    }

    .iv-home-block {
      height: 82px;

      &:nth-child(1) {
        background-image: url('~@/assets/images/new-index/db.png');
        background-size: 100% 100%;
      }

      &:nth-child(2) {
        background-image: url('~@/assets/images/new-index/xx.png');
        background-size: 100% 100%;
      }

      .iv-border {
        width: 48px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 16px;
        line-height: 48px;
        text-align: center;
        margin-left: 24px;
      }

      &:first-child {
        margin-right: 16px;
      }

      flex: 1;
      line-height: 88px;
      background: #FFFFFF;
      border-radius: 16px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      .iconfont {
        font-size: 32px;
        color: #009AD8;
      }

      img {
        margin-top: 9px;
      }

      .iv-home-block-span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 90px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }

      .iv-home-block-right {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .iv-home-bfc {
      display: flex;
      overflow: hidden;
    }
  }
}
</style>
