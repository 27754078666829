/* md5: 0edc4e3a982bbc926f7b74620e38caa8 */
/* 仓库id: 241245 */
/* 版本: 0.0.1-beta.0 */
/* eslint-disable */
/* tslint:disable */

/**
 * 本文件由接口平台自动生成，请勿修改
 * 仓库地址: http://gitee/repository/editor?id=241245
 */
import { string, number, date, array, object } from './utils'

export const getlistApplication = {
  Req: {
    "bizTypeCode": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getlistSignUpApplication = {
  Req: {
    "bizTypeCode": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getlistGetRewardApplication = {
  Req: {
    "bizTypeCode": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetApplication = {
  Req: {
    "applicationInfoId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetMySignUpList = {
  Req: {
    "applicationObjectId": {
        "type": String,
        "required": true
    },
    "applicationYear": String
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getpreSignUp = {
  Req: {},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetProjectSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "orgId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postsubmitProjectSignUp = {
  Req: {
    "projPartyOrgLeaderId": String,
    "projPartyOrgLeaderName": String,
    "projName": String,
    "signUpType": String,
    "agentSignUpOrgId": String,
    "agentSignUpOrgName": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "projClassify": String,
    "recommendOrderNum": Number,
    "projStartDate": Date,
    "projEndDate": Date,
    "projLeaderId": String,
    "projLeaderName": String,
    "projLeaderJobNumber": String,
    "projLeaderEmail": String,
    "projLeaderAdminPost": String,
    "projLeaderPartyPost": String,
    "projBelongArea": String,
    "projPartyOrgId": String,
    "projPartyOrgName": String,
    "projPartyOrgMemberNum": String,
    "projPartyOrgWorkerNum": String,
    "projDepartment": String,
    "projEstablishmentTime": Date,
    "projProgress": Number,
    "projCompletionTime": Date,
    "projBackground": String,
    "projDesignAndIdeas": String,
    "stepsAndProcedures": String,
    "highlightsAndInnovations": String,
    "projPrimaryCoverage": String,
    "coveredPeopleNumber": Number,
    "participationRate": Number,
    "fundsBudget": String,
    "outputCompleteLastYear": String,
    "yieldRateCompleteLastYear": String,
    "outputCompleteThisYear": String,
    "yieldRateCompleteThisYear": String,
    "projPhotoFileId": String,
    "projCategory": String,
    "projLevel": String,
    "serviceOrgLeader": String,
    "serviceOrgLeaderEvaluation": String,
    "applicableObject": String,
    "solvedProblems": String,
    "advancingIdeas": String,
    "projResults": String,
    "innovativePerformance": String,
    "customerSatisfaction": String,
    "promotionSituation": String,
    "nextPromotionPlan": String,
    "competitionIntroduction": String,
    "remarks": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date,
    "attachmentGroupId": String,
    "reportListId": String,
    "teamMemberLists": Array,
    "teamMemberLists[0]": Object,
    "teamMemberLists[0].teamMemberId": String,
    "teamMemberLists[0].memberUserId": String,
    "teamMemberLists[0].memberUserName": String,
    "teamMemberLists[0].projRole": String,
    "teamMemberLists[0].contributionDegree": Number,
    "teamMemberLists[0].bonusPoints": Number,
    "progressLists": Array,
    "progressLists[0]": Object,
    "progressLists[0].progressId": String,
    "progressLists[0].phaseName": String,
    "progressLists[0].markingCompletionRate": Number,
    "progressLists[0].plannedCompletionDate": Date,
    "progressLists[0].actualCompletionDate": Date
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const puteditProjectSignUp = {
  Req: {
    "signUpId": {
        "required": true
    },
    "projPartyOrgLeaderId": String,
    "projPartyOrgLeaderName": String,
    "projName": String,
    "signUpType": String,
    "agentSignUpOrgId": String,
    "agentSignUpOrgName": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "projClassify": String,
    "recommendOrderNum": Number,
    "projStartDate": Date,
    "projEndDate": Date,
    "projLeaderId": String,
    "projLeaderName": String,
    "projLeaderJobNumber": String,
    "projLeaderEmail": String,
    "projLeaderAdminPost": String,
    "projLeaderPartyPost": String,
    "projBelongArea": String,
    "projPartyOrgId": String,
    "projPartyOrgName": String,
    "projPartyOrgMemberNum": String,
    "projPartyOrgWorkerNum": String,
    "projDepartment": String,
    "projEstablishmentTime": Date,
    "projProgress": Number,
    "projCompletionTime": Date,
    "projBackground": String,
    "projDesignAndIdeas": String,
    "stepsAndProcedures": String,
    "highlightsAndInnovations": String,
    "projPrimaryCoverage": String,
    "coveredPeopleNumber": Number,
    "participationRate": Number,
    "fundsBudget": String,
    "outputCompleteLastYear": String,
    "yieldRateCompleteLastYear": String,
    "outputCompleteThisYear": String,
    "yieldRateCompleteThisYear": String,
    "projPhotoFileId": String,
    "projCategory": String,
    "projLevel": String,
    "serviceOrgLeader": String,
    "serviceOrgLeaderEvaluation": String,
    "applicableObject": String,
    "solvedProblems": String,
    "advancingIdeas": String,
    "projResults": String,
    "innovativePerformance": String,
    "customerSatisfaction": String,
    "promotionSituation": String,
    "nextPromotionPlan": String,
    "competitionIntroduction": String,
    "remarks": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date,
    "attachmentGroupId": String,
    "reportListId": String,
    "teamMemberLists": Array,
    "teamMemberLists[0]": Object,
    "teamMemberLists[0].teamMemberId": String,
    "teamMemberLists[0].memberUserId": String,
    "teamMemberLists[0].memberUserName": String,
    "teamMemberLists[0].projRole": String,
    "teamMemberLists[0].contributionDegree": Number,
    "teamMemberLists[0].bonusPoints": Number,
    "progressLists": Array,
    "progressLists[0]": Object,
    "progressLists[0].progressId": String,
    "progressLists[0].phaseName": String,
    "progressLists[0].markingCompletionRate": Number,
    "progressLists[0].plannedCompletionDate": Date,
    "progressLists[0].actualCompletionDate": Date
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetIndividualSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "orgId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postsubmitIndividualSignUp = {
  Req: {
    "signUpType": String,
    "agentSignUpOrgId": String,
    "agentSignUpOrgName": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "userId": String,
    "userName": String,
    "political": String,
    "gender": String,
    "nationality": String,
    "nation": String,
    "photo": String,
    "birthday": Date,
    "age": Number,
    "joinPartyDate": Date,
    "inDeptDate": Date,
    "workingAge": Number,
    "employeeNumber": String,
    "idCardNum": String,
    "education": String,
    "email": String,
    "phone": String,
    "band": String,
    "partyOrgId": String,
    "partyOrgName": String,
    "unionOrgId": String,
    "unionOrgName": String,
    "companyOrgId": String,
    "companyOrgName": String,
    "companyWomenWorkerNum": Number,
    "teamWorkerNum": Number,
    "departmentName": String,
    "officeName": String,
    "workPost": String,
    "workPostDate": Date,
    "hrDuty": String,
    "partyJobStartDate": Date,
    "workType": String,
    "workContent": String,
    "remark": String,
    "performanceThisYear": String,
    "performanceThisYearHalf": String,
    "performanceLastYear": String,
    "performanceYearBeforeLast": String,
    "goalsThisYear": String,
    "skillCompetitionName": String,
    "contributionPoint": String,
    "teamProductNum": String,
    "personalProductNum": String,
    "personalPatentNum": String,
    "internationalPatentNum": String,
    "achievementThisYear": String,
    "achievementValue": String,
    "achievementProductBenefit": String,
    "rationaliProjName": String,
    "rationaliProjImplDate": Date,
    "rationaliProjSaveMoney": String,
    "rationaliProjIncrIncome": String,
    "rationaliProjIncrEfficient": String,
    "rationaliProjImplementation": String,
    "financeProjName": String,
    "financeDeptGoals": String,
    "financeDeptSaveMoney": String,
    "financeDeptIncrIncome": String,
    "financeDeptLeader": String,
    "financeDeptAppraisalDate": Date,
    "techDeptProjName": String,
    "techDeptIncrEfficient": String,
    "techDeptLeader": String,
    "techDeptAppraisalDate": Date,
    "contributionsAndResults": String,
    "mainDeeds": String,
    "deedsIntroduction": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date,
    "rewardsList": Array,
    "rewardsList[0]": Object,
    "rewardsList[0].awardOrganization": String,
    "rewardsList[0].rewardTitle": String,
    "rewardsList[0].orderNum": Number,
    "rewardsList[0].rewardDate": Date,
    "resumeInfoList": Array,
    "resumeInfoList[0]": Object,
    "resumeInfoList[0].unitName": String,
    "resumeInfoList[0].postName": String,
    "resumeInfoList[0].orderNum": Number,
    "resumeInfoList[0].startDate": Date,
    "resumeInfoList[0].endDate": Date
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const puteditIndividualSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "signUpType": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "userId": String,
    "userName": String,
    "political": String,
    "gender": String,
    "nationality": String,
    "nation": String,
    "photo": String,
    "birthday": Date,
    "age": Number,
    "joinPartyDate": Date,
    "inDeptDate": Date,
    "workingAge": Number,
    "employeeNumber": String,
    "idCardNum": String,
    "education": String,
    "email": String,
    "phone": String,
    "band": String,
    "partyOrgId": String,
    "partyOrgName": String,
    "unionOrgId": String,
    "unionOrgName": String,
    "companyOrgId": String,
    "companyOrgName": String,
    "companyWomenWorkerNum": Number,
    "teamWorkerNum": Number,
    "departmentName": String,
    "officeName": String,
    "workPost": String,
    "workPostDate": Date,
    "hrDuty": String,
    "partyJobStartDate": Date,
    "workType": String,
    "workContent": String,
    "remark": String,
    "performanceThisYear": String,
    "performanceThisYearHalf": String,
    "performanceLastYear": String,
    "performanceYearBeforeLast": String,
    "goalsThisYear": String,
    "skillCompetitionName": String,
    "contributionPoint": String,
    "teamProductNum": String,
    "personalProductNum": String,
    "personalPatentNum": String,
    "internationalPatentNum": String,
    "achievementThisYear": String,
    "achievementValue": String,
    "achievementProductBenefit": String,
    "rationaliProjName": String,
    "rationaliProjImplDate": Date,
    "rationaliProjSaveMoney": String,
    "rationaliProjIncrIncome": String,
    "rationaliProjIncrEfficient": String,
    "rationaliProjImplementation": String,
    "financeProjName": String,
    "financeDeptGoals": String,
    "financeDeptSaveMoney": String,
    "financeDeptIncrIncome": String,
    "financeDeptLeader": String,
    "financeDeptAppraisalDate": Date,
    "techDeptProjName": String,
    "techDeptIncrEfficient": String,
    "techDeptLeader": String,
    "techDeptAppraisalDate": Date,
    "contributionsAndResults": String,
    "mainDeeds": String,
    "deedsIntroduction": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date,
    "rewardsList": Array,
    "rewardsList[0]": Object,
    "rewardsList[0].rewardId": String,
    "rewardsList[0].rewardDate": Date,
    "rewardsList[0].awardOrganization": String,
    "rewardsList[0].rewardTitle": String,
    "rewardsList[0].orderNum": Number,
    "resumeInfoList": Array,
    "resumeInfoList[0]": Object,
    "resumeInfoList[0].resumeInfoId": String,
    "resumeInfoList[0].startDate": Date,
    "resumeInfoList[0].endDate": Date,
    "resumeInfoList[0].unitName": String,
    "resumeInfoList[0].postName": String,
    "resumeInfoList[0].orderNum": Number
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetGroupSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "orgId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postsubmitGroupSignUp = {
  Req: {
    "signUpType": String,
    "agentSignUpOrgId": String,
    "agentSignUpOrgName": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "companyWomenNum": Number,
    "teamName": String,
    "teamLeaderId": String,
    "teamLeaderName": String,
    "teamLeaderContact": String,
    "teamMemberNum": Number,
    "memberAvgAge": Number,
    "teamWomenNum": Number,
    "teamWomenRatio": Number,
    "teamWomenLeaderNum": Number,
    "orgId": String,
    "orgName": String,
    "remark": String,
    "contributionsAndResults": String,
    "mainDeeds": String,
    "deedsIntroduction": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date,
    "rewardsList": Array,
    "rewardsList[0]": Object,
    "rewardsList[0].awardOrganization": String,
    "rewardsList[0].rewardTitle": String,
    "rewardsList[0].orderNum": Number,
    "rewardsList[0].rewardDate": Date,
    "teamMemberList": Array,
    "teamMemberList[0]": Object,
    "teamMemberList[0].memberUserId": String,
    "teamMemberList[0].memberUserName": String,
    "teamMemberList[0].employeeNumber": String,
    "teamMemberList[0].orderNum": Number
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const puteditGroupSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "signUpType": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "companyWomenNum": Number,
    "teamName": String,
    "teamLeaderId": String,
    "teamLeaderName": String,
    "teamLeaderContact": String,
    "teamMemberNum": Number,
    "memberAvgAge": Number,
    "teamWomenNum": Number,
    "teamWomenRatio": Number,
    "teamWomenLeaderNum": Number,
    "orgId": String,
    "orgName": String,
    "remark": String,
    "contributionsAndResults": String,
    "mainDeeds": String,
    "deedsIntroduction": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date,
    "rewardsList": Array,
    "rewardsList[0]": Object,
    "rewardsList[0].rewardId": String,
    "rewardsList[0].rewardDate": Date,
    "rewardsList[0].awardOrganization": String,
    "rewardsList[0].rewardTitle": String,
    "rewardsList[0].orderNum": Number,
    "teamMemberList": Array,
    "teamMemberList[0]": Object,
    "teamMemberList[0].teamMemberId": String,
    "teamMemberList[0].memberUserId": String,
    "teamMemberList[0].memberUserName": String,
    "teamMemberList[0].employeeNumber": String,
    "teamMemberList[0].orderNum": Number
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetApprProjectSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "orgId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postsubmitApprProjectSignUp = {
  Req: {
    "signUpType": String,
    "agentSignUpOrgId": String,
    "agentSignUpOrgName": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "projName": String,
    "projApplicantDep": String,
    "isReportToGroup": String,
    "projLeaderId": String,
    "projLeaderName": String,
    "projLeaderJobNumber": String,
    "projLeaderEmail": String,
    "projLeaderAdminPost": String,
    "projLeaderPartyPost": String,
    "projPartyOrgId": String,
    "projPartyOrgName": String,
    "projPartyOrgMemberNum": Number,
    "projPartyOrgWorkerNum": Number,
    "projEstablishmentTime": Date,
    "projProgress": Number,
    "projCompletionTime": Date,
    "highlightsAndInnovations": String,
    "projPrimaryCoverage": String,
    "projPhotoFileId": String,
    "projCategory": String,
    "applicableObject": String,
    "solvedProblems": String,
    "advancingIdeas": String,
    "projResults": String,
    "innovativePerformance": String,
    "customerSatisfaction": String,
    "promotionSituation": String,
    "nextPromotionPlan": String,
    "competitionDeeds": String,
    "contributionIncrProdEfficient": String,
    "contributionIncrEntBenefit": String,
    "contributionReductionCost": String,
    "contributionManageCost": String,
    "contributionOtherCost": String,
    "remark": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const puteditApprProjectSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "signUpType": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": String,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "projName": String,
    "projApplicantDep": String,
    "isReportToGroup": String,
    "projLeaderId": String,
    "projLeaderName": String,
    "projLeaderJobNumber": String,
    "projLeaderEmail": String,
    "projLeaderAdminPost": String,
    "projLeaderPartyPost": String,
    "projPartyOrgId": String,
    "projPartyOrgName": String,
    "projPartyOrgMemberNum": Number,
    "projPartyOrgWorkerNum": Number,
    "projEstablishmentTime": Date,
    "projProgress": Number,
    "projCompletionTime": Date,
    "highlightsAndInnovations": String,
    "projPrimaryCoverage": String,
    "projPhotoFileId": String,
    "projCategory": String,
    "applicableObject": String,
    "solvedProblems": String,
    "advancingIdeas": String,
    "projResults": String,
    "innovativePerformance": String,
    "customerSatisfaction": String,
    "promotionSituation": String,
    "nextPromotionPlan": String,
    "competitionDeeds": String,
    "contributionIncrProdEfficient": String,
    "contributionIncrEntBenefit": String,
    "contributionReductionCost": String,
    "contributionManageCost": String,
    "contributionOtherCost": String,
    "remark": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetOrgSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "orgId": String
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postsubmitOrgSignUp = {
  Req: {
    "signUpType": String,
    "agentSignUpOrgId": String,
    "agentSignUpOrgName": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "orgId": String,
    "orgName": String,
    "approvalBuildingDate": Date,
    "companyShortName": String,
    "orgLeaderName": String,
    "orgLeaderGender": String,
    "orgLeaderAge": String,
    "orgLeaderJobNumber": String,
    "orgLeaderPolitical": String,
    "orgLeaderPhone": String,
    "parentOrgId": String,
    "parentOrgName": String,
    "parentLeaderName": String,
    "parentContacterName": String,
    "parentContacterPhone": String,
    "partyMemberNum": Number,
    "employeeNum": Number,
    "competeEmployeeNum": Number,
    "competeEmployeeNumRatio": String,
    "projectBuildNum": String,
    "projectCloseNum": String,
    "projectOrganizedYear": String,
    "projectEffectiveness": String,
    "remark": String,
    "leagueOrgPeopleNum": Number,
    "leagueOrgMenNum": Number,
    "leagueOrgWomenNum": Number,
    "leagueOrgPartyMemberNum": Number,
    "leagueOrgMemberNum": Number,
    "leagueOrgAvgAge": String,
    "leagueOrgBachelorNum": Number,
    "leagueOrgSpecialtyNum": Number,
    "leagueOrgHighNum": Number,
    "contributionIncrProdEfficient": String,
    "contributionIncrEntBenefit": String,
    "contributionReductionCost": String,
    "contributionManageCost": String,
    "contributionOtherCost": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const puteditOrgSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "signUpType": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "orgId": String,
    "orgName": String,
    "approvalBuildingDate": Date,
    "companyShortName": String,
    "orgLeaderName": String,
    "orgLeaderGender": String,
    "orgLeaderAge": String,
    "orgLeaderJobNumber": String,
    "orgLeaderPolitical": String,
    "orgLeaderPhone": String,
    "parentOrgId": String,
    "parentOrgName": String,
    "parentLeaderName": String,
    "parentContacterName": String,
    "parentContacterPhone": String,
    "partyMemberNum": Number,
    "employeeNum": Number,
    "competeEmployeeNum": Number,
    "competeEmployeeNumRatio": String,
    "projectBuildNum": String,
    "projectCloseNum": String,
    "projectOrganizedYear": String,
    "projectEffectiveness": String,
    "remark": String,
    "leagueOrgPeopleNum": Number,
    "leagueOrgMenNum": Number,
    "leagueOrgWomenNum": Number,
    "leagueOrgPartyMemberNum": Number,
    "leagueOrgMemberNum": Number,
    "leagueOrgAvgAge": String,
    "leagueOrgBachelorNum": Number,
    "leagueOrgSpecialtyNum": Number,
    "leagueOrgHighNum": Number,
    "contributionIncrProdEfficient": String,
    "contributionIncrEntBenefit": String,
    "contributionReductionCost": String,
    "contributionManageCost": String,
    "contributionOtherCost": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetExternalSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "orgId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postsubmitExternalSignUp = {
  Req: {
    "signUpType": String,
    "agentSignUpOrgId": String,
    "agentSignUpOrgName": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "rewardType": String,
    "socialHonorName": String,
    "rewardCategory": String,
    "organizationCategory": String,
    "rewardState": String,
    "ranking": String,
    "rewardTime": Date,
    "rewardUserId": String,
    "rewardUserName": String,
    "rewardOrgId": String,
    "rewardOrgName": String,
    "isExcitation": String,
    "isGetCertificate": String,
    "certificateIssuingDep": String,
    "certificateFileGroupId": String,
    "partyExcitation": String,
    "personalOrgExcitation": String,
    "remark": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": Date
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const puteditExternalSignUp = {
  Req: {
    "signUpId": {
        "type": String,
        "required": true
    },
    "signUpType": String,
    "applicationObjectId": String,
    "recommendOrgId": String,
    "recommendOrgName": String,
    "recommendOrderNum": Number,
    "fillInUserId": String,
    "fillInUserName": String,
    "fillInUserEmail": String,
    "fillInUserPhone": String,
    "rewardType": String,
    "socialHonorName": String,
    "rewardCategory": String,
    "organizationCategory": String,
    "rewardState": String,
    "ranking": String,
    "rewardTime": Date,
    "rewardUserId": String,
    "rewardUserName": String,
    "rewardOrgId": String,
    "rewardOrgName": String,
    "isExcitation": String,
    "isGetCertificate": String,
    "certificateIssuingDep": String,
    "certificateFileGroupId": String,
    "partyExcitation": String,
    "personalOrgExcitation": String,
    "remark": String,
    "attachmentGroupId": String,
    "reportListId": String,
    "createUserId": String,
    "createUserName": String,
    "createTime": Date,
    "lastModifyUserId": String,
    "lastModifyUserName": String,
    "lastModifyTime": String
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}