<template>
  <Block title="建议反馈" :bgImg="'xinsheng'" class="iv-m-t-16" style="width: 469px;height: 332px" @on-more="toMorePage">
    <ul class="iv-institutional-index" v-if="list.length > 0">
      <li class="iv-institutional-index-li iv-pull-left iv-fc-65" v-for="(item, index) in list" :key="item.categoryId" @click="todetail(item)">
        <div class="prefix">{{(index + 1).toString().padStart(2, '0')}}</div>
        <p :title="item.title">{{item.title}}</p>
      </li>
    </ul>
    <NoData v-if="list.length <= 0"></NoData>
  </Block>
</template>

<script>
import Block from '@/components/block'
import NoData from '@/components/no-data'
import IconFont from '@/components/iconfont'
// import {titleConfig} from './index-config.js'

export default {
  name: 'institutionalIndex',
  data () {
    return {
      list: []
    }
  },
  created () {
    this.$ajax({
      url: 'api-general/module/portal/voiceAdvice/list',
      method: 'get',
      data: {
        currentPage: 1,
        pageSize: 6,
        handleState: 'finish',
        isPublic: 'yes'
      }
    }).then(({data}) => {
      this.list = data.data
    })
  },
  methods: {
    //点击更多
    toMorePage () {
      // this.$Message.info("功能暂未上线，敬请期待！")
      //暂时不开通
      const { href } = this.$router.resolve({
        name: 'voiceAdviceList'
      })
      window.open(href, '_blank')
    },
    todetail (item) {
      const { href } = this.$router.resolve({
        name: 'viewResult',
        query: {
          handleState: item.handleState,
          voiceAdviceId: item.voiceAdviceId
        }
      })
      window.open(href, '_blank')
    }
  },
  components: {
    Block,
    NoData,
    IconFont
  }
}
</script>

<style lang="scss" scoped>
.iv-institutional-index {
  // background: #fff;
  overflow: hidden;
  .iv-institutional-index-li {
    width: 100%;
    cursor: pointer;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 26px;
    margin-top: 14px;
    .prefix {
      // width: 6px;
      // height: 6px;
      // background: #FFC757;
      // border-radius: 50%;
      font-weight: 700;
      font-size: 20px;
    }
    p {
      margin: unset;
      margin-left: 14px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-family: 'Microsoft YaHei';
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover {
      p {
        color: #2373C8;
        text-decoration-line: underline;
      }
      .prefix {
        color: #2373C8;
      }
    }
  }
}
</style>
