<template>
  <div class="iv-text-center iv-m-t-40">
    <img src="@/assets/images/no-data.png" />
    <p class="iv-fs-16 iv-fc-45 iv-m-t-20">{{message}}</p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '暂无数据'
    }
  }
}  
</script>
