/* md5: 0edc4e3a982bbc926f7b74620e38caa8 */
/* 仓库id: 241245 */
/* 版本: 0.0.1-beta.0 */
/* eslint-disable */
/* tslint:disable */

/**
 * 本文件由接口平台自动生成，请勿修改
 * 仓库地址: http://gitee/repository/editor?id=241245
 */
import axios from '@/plugins/axios'
import { downLoad } from '@/utils'
import {
  getlistApplication,
  getlistSignUpApplication,
  getlistGetRewardApplication,
  getgetApplication,
  getgetMySignUpList,
  getpreSignUp,
  getgetProjectSignUp,
  postsubmitProjectSignUp,
  puteditProjectSignUp,
  getgetIndividualSignUp,
  postsubmitIndividualSignUp,
  puteditIndividualSignUp,
  getgetGroupSignUp,
  postsubmitGroupSignUp,
  puteditGroupSignUp,
  getgetApprProjectSignUp,
  postsubmitApprProjectSignUp,
  puteditApprProjectSignUp,
  getgetOrgSignUp,
  postsubmitOrgSignUp,
  puteditOrgSignUp,
  getgetExternalSignUp,
  postsubmitExternalSignUp,
  puteditExternalSignUp
} from './model'
import {ValidateType} from './model/utils'
const isPod = process.env.NODE_ENV === 'production'

let validatelistApplication = new ValidateType(getlistApplication.Req, 'api-general/api-general/module/selection/personalCenter/application/listApplication', 'GET')
export function listApplication (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatelistApplication.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/application/listApplication',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatelistSignUpApplication = new ValidateType(getlistSignUpApplication.Req, 'api-general/api-general/module/selection/personalCenter/application/listSignUpApplication', 'GET')
export function listSignUpApplication (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatelistSignUpApplication.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/application/listSignUpApplication',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatelistGetRewardApplication = new ValidateType(getlistGetRewardApplication.Req, 'api-general/api-general/module/selection/personalCenter/application/listGetRewardApplication', 'GET')
export function listGetRewardApplication (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatelistGetRewardApplication.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/application/listGetRewardApplication',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetApplication = new ValidateType(getgetApplication.Req, 'api-general/api-general/module/selection/personalCenter/application/getApplication', 'GET')
export function getApplication (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetApplication.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/application/getApplication',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetMySignUpList = new ValidateType(getgetMySignUpList.Req, 'api-general/api-general/module/selection/personalCenter/signUp/getMySignUpList', 'GET')
export function getMySignUpList (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetMySignUpList.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/getMySignUpList',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatepreSignUp = new ValidateType(getpreSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/common/preSignUp', 'GET')
export function preSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatepreSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/common/preSignUp',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetProjectSignUp = new ValidateType(getgetProjectSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/project/getProjectSignUp', 'GET')
export function getProjectSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetProjectSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/project/getProjectSignUp',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatesubmitProjectSignUp = new ValidateType(postsubmitProjectSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/project/submitProjectSignUp', 'POST')
export function submitProjectSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatesubmitProjectSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/project/submitProjectSignUp',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateeditProjectSignUp = new ValidateType(puteditProjectSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/project/editProjectSignUp', 'PUT')
export function editProjectSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateeditProjectSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/project/editProjectSignUp',
    method: 'PUT',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetIndividualSignUp = new ValidateType(getgetIndividualSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/getIndividualSignUp', 'GET')
export function getIndividualSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetIndividualSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/getIndividualSignUp',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatesubmitIndividualSignUp = new ValidateType(postsubmitIndividualSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/submitIndividualSignUp', 'POST')
export function submitIndividualSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatesubmitIndividualSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/submitIndividualSignUp',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateeditIndividualSignUp = new ValidateType(puteditIndividualSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/editIndividualSignUp', 'PUT')
export function editIndividualSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateeditIndividualSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/editIndividualSignUp',
    method: 'PUT',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetGroupSignUp = new ValidateType(getgetGroupSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/getGroupSignUp', 'GET')
export function getGroupSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetGroupSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/getGroupSignUp',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatesubmitGroupSignUp = new ValidateType(postsubmitGroupSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/submitGroupSignUp', 'POST')
export function submitGroupSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatesubmitGroupSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/submitGroupSignUp',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateeditGroupSignUp = new ValidateType(puteditGroupSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/editGroupSignUp', 'PUT')
export function editGroupSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateeditGroupSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/editGroupSignUp',
    method: 'PUT',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetApprProjectSignUp = new ValidateType(getgetApprProjectSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/getApprProjectSignUp', 'GET')
export function getApprProjectSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetApprProjectSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/getApprProjectSignUp',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatesubmitApprProjectSignUp = new ValidateType(postsubmitApprProjectSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/submitApprProjectSignUp', 'POST')
export function submitApprProjectSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatesubmitApprProjectSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/submitApprProjectSignUp',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateeditApprProjectSignUp = new ValidateType(puteditApprProjectSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/editApprProjectSignUp', 'PUT')
export function editApprProjectSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateeditApprProjectSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/editApprProjectSignUp',
    method: 'PUT',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetOrgSignUp = new ValidateType(getgetOrgSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/getOrgSignUp', 'GET')
export function getOrgSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetOrgSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/getOrgSignUp',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatesubmitOrgSignUp = new ValidateType(postsubmitOrgSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/submitOrgSignUp', 'POST')
export function submitOrgSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatesubmitOrgSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/submitOrgSignUp',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateeditOrgSignUp = new ValidateType(puteditOrgSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/editOrgSignUp', 'PUT')
export function editOrgSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateeditOrgSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/editOrgSignUp',
    method: 'PUT',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetExternalSignUp = new ValidateType(getgetExternalSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/getExternalSignUp', 'GET')
export function getExternalSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetExternalSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/getExternalSignUp',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatesubmitExternalSignUp = new ValidateType(postsubmitExternalSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/submitExternalSignUp', 'POST')
export function submitExternalSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatesubmitExternalSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/submitExternalSignUp',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateeditExternalSignUp = new ValidateType(puteditExternalSignUp.Req, 'api-general/api-general/module/selection/personalCenter/signUp/appraising/editExternalSignUp', 'PUT')
export function editExternalSignUp (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateeditExternalSignUp.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/selection/personalCenter/signUp/appraising/editExternalSignUp',
    method: 'PUT',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}