<template>
  <Block title="制度索引" :bgImg="'zhidu'" class="iv-m-t-16" style="width: 469px;height: 332px;" @on-more="toMorePage">
    <div class="iv-institutional-page iv-pos-r" @mouseenter="showBtn" @mouseleave="hiddenBtn">
      <Swiper v-if="list.length" class="iv-carou" ref="mySwiper" :options="swiperOptions">
        <SwiperSlide class="iv-swiper-wrapper" v-for="(v, k) in list" :key="k">
          <ul class="iv-institutional-index" v-if="v.length > 0">
            <li class="iv-institutional-index-li iv-pull-left iv-fc-65" v-for="item in v" :key="item.categoryId" @click="todetail(item)">
              <p>{{item.name}}</p>
              <IconFont type="zhiduchaxun"></IconFont>
            </li>
          </ul>
        </SwiperSlide>
      </Swiper>
      <div v-if="list.length > 1" ref="prevBtn" class="swiper-button-prev"></div>
      <div v-if="list.length > 1" ref="nextBtn" class="swiper-button-next"></div>
    </div>
    <NoData v-if="!list.length"></NoData>
  </Block>
</template>

<script>
import { getToken } from '@/utils'
import Block from '@/components/block'
import NoData from '@/components/no-data'
import IconFont from '@/components/iconfont'
// swiper
import { Swiper as SwiperClass, Navigation } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
SwiperClass.use([Navigation])
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: 'institutionalIndex',
  data () {
    return {
      list: [],
      swiperOptions: {
        loop: false,
        autoplay: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      },
      allId: '',
      cmsUrl: ''
    }
  },
  created () {
    this.cmsUrl = sessionStorage.getItem('cmsUrl')
    this.loop()
  },
  methods: {
    loop () {
      if (!this.cmsUrl) {
        setTimeout(() => {
          this.cmsUrl = sessionStorage.getItem('cmsUrl')
          this.loop()
        }, 500)
      } else {
        this.getListData()
      }
    },
    getListData () {
      this.$ajax({
        url: `${this.cmsUrl}/api-qixuan/cms/front/getZDColumnList`,
        method: 'get',
        data: {
          rowNum: 4
        }
      }).then(({data}) => {
        this.allId = data.id || ''
        if (!data.data || !data.data.length) return
        //let i = 0
        data.data.map(item => {
          if (item.name === '制度索引') {
            this.getSecondaryMenu(item)
          }
        })
        // data.data.forEach((item, index) => {
        //   if (index % 4 == 0 && index !== 0) {
        //     i++
        //   }
        //   if (!this.list[i]) {
        //     this.$set(this.list, i, [])
        //   }
        //   this.list[i].push(item)
        // })
      })
    },
    //获取制度索引二级菜单
    getSecondaryMenu(item) {
      this.$ajax({
        url: `${this.cmsUrl}/api-qixuan/cms/front/getColumn2List`,
        method: 'get',
        data: {
          siteId: item.siteId,
          id: item.id
        }
      }).then(({data}) => {
        let i = 0
        data.data.forEach((item, index) => {
          if (index % 4 == 0 && index !== 0) {
            i++
          }
          if (!this.list[i]) {
            this.$set(this.list, i, [])
          }
          this.list[i].push(item)
        })
      })
    },
    //点击更多
    toMorePage () {
      const url = sessionStorage.getItem('jumpCmsUrl')
      const href = `${url}/#/party_lead?categoryCode=zdsy&categoryName=制度索引&categoryId=${this.allId}&categoryType=1&access_token=${getToken()}`
      window.open(href, '_blank')
    },
    todetail (item) {
      const url = sessionStorage.getItem('jumpCmsUrl')
      //const href = `${url}/#/party_lead?categoryCode=zdsy&categoryName=${item.name}&categoryId=${item.id}&categoryType=${item.type}&access_token=${getToken()}`
      const href = `${url}/#/party_lead?categoryCode=zdsy&categoryName=制度索引&categoryId=${this.allId}&categoryType=1&access_token=${getToken()}&secondaryCategoryId=${item.id}`
      window.open(href, '_blank')
    },
    //显示上一页下一页按钮
    showBtn(){
      this.$refs.prevBtn.style.visibility = 'visible'
      this.$refs.nextBtn.style.visibility = 'visible'
    },
    //隐藏上一页下一页按钮
    hiddenBtn(){
      this.$refs.prevBtn.style.visibility = 'hidden'
      this.$refs.nextBtn.style.visibility = 'hidden'
    }
  },
  components: {
    Block,
    NoData,
    IconFont,
    Swiper,
    SwiperSlide
  }
}
</script>

<style lang="scss" scoped>
.iv-institutional-index {
  // background: #fff;
  overflow: hidden;
  .iv-institutional-index-li {
    float: left;
    width: 210px;
    height: 110px;
    border-radius: 8px;
    margin-top: 16px !important;
    cursor: pointer;
    // background: url('~@/assets/images/index/ins-bg.png') no-repeat center center;
    margin-right: 16px;
    background: #F7FAFF;
    position: relative;
    // padding: 0 30px 0 18px;
    // &:nth-child(1) {
    //   background-color: rgb(255, 250, 249);
    // }
    // &:nth-child(2) {
    //   background-color: rgba(69, 188, 125, 0.05);
    // }
    // &:nth-child(3) {
    //   background-color: rgba(255, 199, 87, 0.05);
    // }
    // &:nth-child(4) {
    //   background-color: rgba(255, 143, 130, 0.05);
    // }
    &:nth-child(2n) {
      margin-right: 0px;
    }
    &:nth-child(3) {
      margin-top: 16px !important;
      // margin-bottom: 28px;
    }
    &:nth-child(4) {
      margin-top: 16px !important;
      // margin-bottom: 28px;
    }
    .iconfont {
      font-size: 40px;
      position: absolute;
      bottom: -7px;
      right: 4px;
      color: #CCCCCC;
    }
    p {
      // margin: 20px 0 0 20px;
      // float: right;
      // font-size: 16px;
      // color: rgba(0, 0, 0, 0.65);
      // line-height: 18px;
      white-space: pre-wrap;
      // width: 100px;
      // margin-right: 10px;
      // margin-top: 22px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 18px;
      margin-top: 28px;
      margin-left: 20px;
    }
  }
}
.swiper-button-prev {
  left: -16px;
  visibility: hidden;
}
.swiper-button-next {
  right: -16px;
  visibility: hidden;
  &::after {
    padding-left: 4px;
  }
}
.swiper-button-prev, .swiper-button-next {
  width: 35.56px;
  height: 35.56px;
  top: 150px;
  background: rgba(0,0,0,0.4);
  color: #fff;
  border-radius: 50%;
  &::after {
    font-size: 20px;
  }
}
.swiper-button-disabled {
  pointer-events: all
}
</style>
