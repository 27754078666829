<template>
  <Block title="推荐学习" :left="3" :size="18" type="tuijianxuexi" :imgBorder="'zizhu'" :headerImg="'zizhu'" class="iv-m-t-16" style="width: 100%;height: 332px;" @on-more="toMorePage">
    <ul v-if="cardList.length > 0" class="iv-course">
      <li class="iv-course-li iv-pointer" v-for="(item, index) in cardList" :key="index" @click="toCourseDetail(item)">
        <div class="iv-course-li-img">
          <img :src="getCoverImage(item.course.coverImage)" alt="">
        </div>
        <div class="iv-course-content">
          <h3 class="course-name iv-fs-16 iv-fc-65 iv-ellipsis-2" :title="item.course.courseName">{{item.course.courseName}}</h3>
          <p class="iv-fs-16 iv-fc-45">
            <span>{{item.course.chapterCoursewareNum}}集</span>
            <span>{{Math.round(item.course.courseDuration / 60)}}分钟</span>
            <span>{{item.course.userNum || 0}}人学习</span>
          </p>
        </div>
      </li>
    </ul>
    <NoData v-if="cardList.length == 0"></NoData>
  </Block>
</template>

<script>
import Block from '@/components/block'
import NoData from '@/components/no-data'
import { jumpElearningPage } from '@/utils'
import _ from 'lodash'
import '@/assets/css/iconfont/iconfont.css'

export default {
  name: 'CourseList',
  data () {
    return {
      cardList: [],
      list: []
    }
  },
  created () {
    this.getCourseList()
  },
  methods: {
    getCoverImage (imgId) {
      return `api-file/portal/open/file/stream/${imgId}?origin=true`
    },
    //点击更多
    toMorePage () {
      this.$Message.info("功能暂未上线，敬请期待！")
      //上线后
      // jumpElearningPage('/#/course/course-list')
    },
    //课程详情
    toCourseDetail (item) {
      jumpElearningPage(`/#/course/course-detail?courseID=${item.course.courseID}`)
    },
    //课程列表
    getCourseList () {
      this.$ajax({
        url: `api-information/portal/open/recommend`,
        method: 'get',
        data: {
          searchType: 'course'
        }
      }).then(({data}) => {
        this.list = data.data || []
        this.$nextTick(() => {
          this.watchView()
        })
      })
    },
    watchView() {
      const vWidth = document.documentElement.clientWidth
      if (vWidth < 1366) {
        this.cardList = this.list.slice(0, 3)
      } else {
        this.cardList = this.list.slice(0, 4)
      }
    }
  },
  components: {
    Block,
    NoData
  },
  mounted () {
    window.addEventListener('resize', _.debounce(this.watchView, 200))
  },
  beforeDestroy() {
    window.removeEventListener('resize', _.debounce(this.watchView, 200))
  }
}
</script>

<style lang="scss" scoped>
.iv-course {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  .iv-course-li {
    width: 218px;
    height: 232px;
    border-radius: 8px;
    // background: rgba(0, 0, 0, 0.04);
    .iv-course-li-img {
      height: 126px;
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
    .iv-course-content {
      padding-top: 16px;
      .course-name {
        font-weight: 700;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        // height: 44px;
      }
      p {
        display: flex;
        align-items: center;
        margin-top: 16px;
        span {
          margin-right: 16px;
          position: relative;
          &::after {
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            background: rgba(0, 0, 0, 0.44);
            border-radius: 50%;
          }
          &:last-child {
            margin-right: 0;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
