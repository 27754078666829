<template>
  <Block
      :left="-1"
      type="gongneng"
      :imgBorder="'zizhu'"
      :headerImg="'zizhu'"
      class="iv-m-t-16"
      @on-more="toMorePage"
  >
    <div class="iv-funciton-block">
      <!-- <ul class="iv-funciton-block-ul">
        <li class="iv-funciton-block-li iv-text-center iv-m-t-30 iv-pull-left iv-pointer" v-for="(item, index) in list" :key="index" @click="toThatPage(item)">
          <span class="iv-funciton-block-bg">
            <IconFont :type="item.icon"></IconFont>
          </span>
          <p class="iv-fs-14 iv-fc-85">{{item.title}}</p>
        </li>
      </ul>-->
      <Row :gutter="24" class="iv-funciton-block-ul iv-p-l-0 iv-p-r-0">
        <Col
            :span="3"
            class="iv-funciton-block-li iv-text-center iv-m-t-30 iv-pull-left iv-pointer"
            v-for="(item, index) in list"
            :key="index"
            v-if="(item.icon != 'jiesujiban' && item.icon != 'xiehui' && item.icon !='kunnan' && item.icon != 'helishangcheng' && item.icon !== 'dangyuanzhuanqu') || (item.icon === 'jiesujiban' && isShowJB) || (item.icon === 'xiehui' && isShowXH || (item.icon === 'kunnan' && isShowKNBF) || (item.icon === 'helishangcheng' && isShowHLSC) || (item.icon ==='dangyuanzhuanqu' && isShowDY))"
        >
          <div
              @click="toThatPage(item)"
          >
            <span class="iv-funciton-block-bg" :style="{background: item.color}">
              <img
                  :src="item.image"
                  alt="30th"
                  v-if="useImgList.includes(item.icon)"
                  width="35"
              />
              <!-- <img
                src="@/assets/images/function-12345-icon.png"
                alt="30th"
                v-if="item.icon === 'jiesujiban'"
                width="35"
              />
              <img
                src="@/assets/images/function-xiehui-icon.png"
                alt="30th"
                v-if="item.icon === 'xiehui'"
                width="35"
              /> -->
              <IconFont :type="item.icon" v-else></IconFont>
            </span>
            <p class="iv-fs-14 iv-fc-85">{{ item.title }}</p>
          </div>
        </Col>
      </Row>
    </div>
    <Modal
        title="自主功能入口"
        class="iv-class-div iv-function-modal"
        v-model="isShowModal"
        :map="map"
        size="middle"
        :readOnly="true"
        buttonText="关闭"
    >
      <Draggable
          :sort="false"
          v-model="allList"
          group="list"
          animation="1000"
          element="ul"
          class="iv-funciton-block-ul-modal iv-p-l-20 iv-p-b-20"
          @end="dragEnd"
      >
        <transition-group>
          <li
              v-if="(item.icon != 'jiesujiban' && item.icon != 'xiehui' && item.icon != 'helishangcheng' && item.icon != 'dangyuanzhuanqu') || (item.icon === 'jiesujiban' && isShowJB) || (item.icon === 'xiehui' && isShowXH) || (item.icon === 'helishangcheng' && isShowHLSC) || (item.icon ==='dangyuanzhuanqu' && isShowDY)"
              class="iv-funciton-block-li-modal iv-text-center iv-m-t-20 iv-pull-left iv-pointer"
              v-for="item in allListcopy"
              :key="item.index"
              @click="toThatPage(item)"
          >
            <span class="iv-funciton-block-bg" :style="{background: item.color}">
              <img
                  :src="item.image"
                  alt="30th"
                  v-if="useImgList.includes(item.icon)"
                  width="35"
              />
              <!-- <img
                src="@/assets/images/function-30th-icon.png"
                alt="30th"
                v-if="item.icon === '30th'"
                width="35"
              />
              <img
                src="@/assets/images/function-12345-icon.png"
                alt="30th"
                v-if="item.icon === 'jiesujiban'"
                width="35"
              />
              <img
                src="@/assets/images/function-xiehui-icon.png"
                alt="30th"
                v-if="item.icon === 'xiehui'"
                width="35"
              /> -->
              <IconFont :type="item.icon" v-else></IconFont>
            </span>
            <p class="iv-fs-16 iv-fc-65 iv-m-t-8">{{ item.title }}</p>
          </li>
        </transition-group>
      </Draggable>
      <div slot="footer">
        <Button type="close" class="iv-fs-14" @click="closeModal">关闭</Button>
      </div>
    </Modal>
    <Modal title="系统提示" size="small" v-model="pointsModal" class="iv-points-info-modal">
      <div class="iv-p-24 iv-text-center">
        <p class="iv-fs-16 iv-fc-65 iv-text-left">功能将在后期上线，敬请期待...</p>
        <img class="iv-m-t-8" style="height: 173px;" src="@/assets/images/index/no-points.png"/>
      </div>
      <div slot="footer">
        <Button type="close" @click="refusePointsModal">关闭</Button>
      </div>
    </Modal>
  </Block>
</template>

<script>
// import { jumpCmsUrl } from '@/config'
import Draggable from 'vuedraggable'
import Block from '@/components/block'
import Modal from '@/components/modal'
import {jumpElearningPage, getToken} from '@/utils'
import IconFont from '@/components/iconfont'
import {iconConfig, titleConfig, titleColor} from './block-config.js'

export default {
  name: 'functionBlock',
  data() {
    return {
      list: [],
      isShowModal: false,
      isShowHLSC: false,//是否显示合力商城
      isShowDY: false, //是否显示党员专区
      allList: [],
      allListcopy: [],
      noMore: false,
      pointsModal: false,
      defaultSize: 16,
      isShowJB: false, //是否显示接诉即办
      isShowXH: false, //是否显示协会
      isShowKNBF: false, //是否显示困难帮扶
      useImgList: ['30th', 'xinwen4', 'huodong21', 'wenjuan2', 'pingyou', 'xiangmu3', 'pingshen1', 'tougao1', 'jiesujiban', 'xiehui', 'xianshangzhanting', 'helishangcheng', 'dangyuanzhuanqu'], //使用本地图片的列表
    }
  },
  created() {
    this.map = {
      middle: '792',
    }
    this.allList = Object.keys(iconConfig).map((item, index) => ({
      image: this.useImgList.includes(iconConfig[item]) ? require('@/assets/images/' + iconConfig[item] + '.png') : '',
      title: titleConfig[item],
      type: item,
      index: index,
      icon: iconConfig[item],
      color: titleColor[item],
    }))
    //隐掉 在线学习 等
    this.allList.map((item, i) => {
      this.allListcopy.push(item)
    })
    // this.allList.splice(this.allList.length-4, 3)
    this.list = this.allListcopy.slice(0, this.defaultSize)
  },
  mounted() {
    this.checkWelfare()
    this.checkPermission()
    this.checkPermissionXH()
    const screenWidth = document.body.getBoundingClientRect().width
    if (screenWidth > 1366) {
      this.defaultSize = 16
    } else {
      this.defaultSize = 12
    }
    this.list = this.allListcopy.slice(0, this.defaultSize)
    this.getShowDj()
  },
  methods: {
    //判断是否有合力商城权限
    checkWelfare() {
      this.$ajax({
        url: '/api-uum/uuminfo/getUserPosition',
        method: 'get',
      }).then((res) => {
        this.isShowHLSC = res.data.data.welfare
      })
    },
    //判断是否有接诉即办的权限
    checkPermission() {
      this.$ajax({
        url: 'api-jiban/sys/user/checkPermission',
        method: 'get',
      }).then((res) => {
        this.isShowJB = res.data.data;
      })
    },
    checkPermissionXH() {
      this.$ajax({
        url: 'api-gongjian/association/getOrgByUserId',
        method: 'get',
        data: {}
      }).then(({data}) => {
        this.isShowXH = !!data.data.id;
      })
    },
    getShowDj() {
      this.$ajax({
        url: 'api-platform/app/authorization/showDJ',
        method: 'get',
        headers: {
          'App-Code': 'dj'
        }
      }).then(({data}) => {
        console.info(data)
        this.isShowDY = data.data.show
        console.info(this.isShowDY)
        this.dyUrl = data.data.path

      })
    },
    //拖拽结束
    dragEnd(param) {
    },
    //点击更多
    toMorePage() {
      this.isShowModal = true
    },
    //关闭弹框
    closeModal() {
      //关闭弹框时重新赋值
      this.list = this.allListcopy.slice(0, this.defaultSize)
      // this.list = this.allList.slice(0, 10)
      this.isShowModal = false
    },
    refusePointsModal() {
      this.pointsModal = false
    },
    //跳转至对应的模块
    toThatPage(item) {
      if (item.type === 'dyzq') {
        window.open(`${this.dyUrl}?token=${getToken()}`)
        return
      }
      if (item.type === 'hlsc') {//合力商城
        let userCode = btoa(sessionStorage.getItem('userCode'))
        let url = 'https://mall.dangquntong.cn/#/loadingPage?userNo=hl002&uuid=' + userCode;
        window.open(url, '_blank')
        return
      }
      if (item.type === 'xszt') { //线上展厅
        window.open(process.env['VUE_APP_EXHIBIT_URL'])
        return
      }
      if (item.type === '30th') {
        window.open(
            `${
                process.env['VUE_APP_30TH_URL']
            }/#/fromPortal?access_token=${getToken()}`
        )
        return
      }
      if (item.type === 'jiesujiban') {
        //接诉即办
        window.open(
            `${
                process.env['VUE_APP_12345_URL']
            }/#/formProtal?access_token=${getToken()}`
        )
        return
      }
      if (item.type == 'xlxx') {
        //心灵信箱
        this.goHeartMailBox()
        return
      }
      let name = ''
      if (item.type === 'zxxx') {
        //在线学习
        jumpElearningPage('/#/index')
        return
      }
      if (item.type == 'xwzx') {
        //文化宣传
        const href = `${sessionStorage.getItem(
            'jumpCmsUrl'
        )}/#/homePage?access_token=${getToken()}`
        window.open(href, '_blank')
        return
      }
      if (item.type == 'xwtg') {
        //新闻投稿
        const href = `${sessionStorage.getItem(
            'cmsName'
        )}/userDeliver/contribute?access_token=${getToken()}`
        window.open(href, '_blank')
        return
      }
      switch (item.type) {
        case 'xsjy':
          name = 'voiceAdviceList'
          break
        case 'xmsb':
          name = 'projectList'
          break
        case 'pysb':
          name = 'appraisingList'
          break
        case 'hdbm':
          name = 'activityList'
          break
        case 'psdf':
          name = 'reviewList'
          break
        case 'zdsy':
          name = 'systemList'
          break
        case 'knsq':
          name = 'difficultList'
          break
        case 'ljzm':
          name = 'cleanCertificate'
          break
        case 'dcwj':
          name = 'questionnaireList'
          break
        case 'zycsxy':
          name = 'declarationSignList'
          break
        case 'zjyj':
          name = 'solicitOpinions'
          break
        case 'fzdy':
          name = 'recruitingPartyMembersProgressKanban'
          break
        case 'xh':
          name = 'association'
          break
      }
      if (
          name == 'recruitingPartyMembersProgressKanban' &&
          sessionStorage.getItem('polity') == '01'
      ) {
        this.$Message.success('您已经是党员，无需申请入党.')
        return
      }
      this.$router.push({
        name: name,
        query: {},
      })
    },
    goHeartMailBox() {
      this.$ajax({
        url: 'api-uum/module/portal/mailbox/verifyLogin',
        method: 'get',
        data: {},
        preventMessage: true,
      })
          .then(({data}) => {
            if (data.code == -1) {
              this.$Message.error(data.data.message)
            } else {
              let url = `http://boehome.boe.com?app=index&mod=Index&act=index&token=${data.data.token}`
              window.open(url, '_blank')
            }
          })
          .catch(({data}) => {
            let urls = data.data.info
            window.open(urls)
          })
    },
  },

  components: {
    Modal,
    Block,
    Draggable,
    IconFont,
  },
}
</script>

<style lang="scss" scoped>
.iv-block {
  height: 332px !important;
}

.iv-funciton-block-ul {
  overflow: hidden;
  // padding: 0 33px !important;
  margin-top: -10px;
  padding-left: 0px !important;
  padding-right: 0px !important;

  .ivu-col-span-3 {
    width: 14%;
  }
}

.iv-funciton-block-li {
  // width: 56px;
  height: 91px;
  border-radius: 8px;
  // margin-right: 74px;
  margin-right: 0px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid rgba(0, 0, 0, 0.09);
  // &:nth-child(6n) {
  //   margin-right: 0;
  // }
  // img {
  //   width: 48px;
  //   height: 48px;
  //   border-radius: 50%;
  // }
  &:nth-child(6n) {
    margin-left: 0px;
  }

  p {
    font-weight: 500;
    margin-top: 5px;
  }

  .iv-funciton-block-bg {
    color: #fff;
    display: flex;
    width: 56px;
    height: 56px;
    line-height: 56px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    // background: linear-gradient(313.64deg, #6EDFF6 2.27%, #77EBB1 100%);
    // background: url('~@/assets/images/index/blue-bg.png') no-repeat center center;
    position: relative;

    .iconfont {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 33px;
    }
  }
}

.iv-funciton-block-ul-modal {
  padding: 0 16px;

  span {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.iv-funciton-block-li-modal {
  // width: 56px;
  height: 91px;
  border-radius: 8px;
  // margin-right: 74px;
  margin-right: 0px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 40px;
  width: 120px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid rgba(0, 0, 0, 0.09);
  // &:nth-child(6n) {
  //   margin-right: 0;
  // }
  // img {
  //   width: 48px;
  //   height: 48px;
  //   border-radius: 50%;
  // }
  &:nth-child(6n) {
    margin-left: 0px;
  }

  p {
    font-weight: 500;
    margin-top: 5px;
  }

  .iv-funciton-block-bg {
    color: #fff;
    display: flex;
    width: 56px;
    height: 56px;
    line-height: 56px;
    border-radius: 16px;
    // background: linear-gradient(313.64deg, #6EDFF6 2.27%, #77EBB1 100%);
    // background: url('~@/assets/images/index/blue-bg.png') no-repeat center center;
    position: relative;
    align-items: center;
    justify-content: center;

    .iconfont {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 33px;
    }
  }
}
</style>
