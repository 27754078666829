/* md5: 0edc4e3a982bbc926f7b74620e38caa8 */
/* 仓库id: 241245 */
/* 版本: 0.0.1-beta.0 */
/* eslint-disable */
/* tslint:disable */

/**
 * 本文件由接口平台自动生成，请勿修改
 * 仓库地址: http://gitee/repository/editor?id=241245
 * 此工具脚本为示例，具体在生成后模板引用脚本为@/utils/interface-utils
 */
class GetMock {
  get value() {
    let i = ~~(Math.random() * this.valueStore.length), v = this.valueStore[i]
    return v
  }
}

export class StringMock extends GetMock {
  constructor() {
    super()
    this.valueStore = ''
    for (let start = 65, end = 122; start <= end; start++) {
      this.valueStore += String.fromCodePoint(start)
    }
  }
}

export class BooleanMock extends GetMock {
  constructor() {
    super()
    this.valueStore = [false, true]
  }
}

export class NumberMock extends GetMock {
  constructor() {
    super()
    this.valueStore = new Array(100).fill(1).map((n, i) => i + 1)
  }
}

export class ArrayMock extends GetMock {
  constructor() {
    super()
    this.valueStore = []
  }
  gen() {
    this.valueStore.push([...arguments])
    return this
  }
}

export class ObjectMock extends GetMock {
  constructor() {
    super()
    this.valueStore = [{}]
  }
}

export class DateMock extends GetMock {
  constructor() {
    super()
    this.valueStore = [new Date().getTime()]
  }
}

export class FunctionMock extends GetMock {
  constructor() {
    super()
    this.valueStore = [function () { }]
  }
}

export const string = new StringMock()
export const number = new NumberMock()
export const boolean = new BooleanMock()
export const array = new ArrayMock()
export const object = new ObjectMock()
export const date = new DateMock()
function isObject(obj) {
  return obj !== null && typeof obj === 'object'
}
function isPrimitive(value) {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    // $flow-disable-line
    typeof value === 'symbol' ||
    typeof value === 'boolean'
  )
}
export class ValidateType {
  constructor(type = {}, url, method) {
    this.type = type
    this.url = url
    this.method = method
    this.filterKey = ['isEdit', 'pageSize', 'currentPage', 'count', 'maxPage', 'menu-code', 'classifyId']
  }
  setFilterKey (key) {
    this.filterKey.concat(key)
    return this
  }
  validate(data, prefix = '', isArray = false) {
    this.validateType = Object.assign({}, this.type)
    validateHandler.call(this, ...arguments)
    function validateHandler(data, prefix = '', isArray = false) {
      let keys = Object.keys(data), types = Object.keys(this.type), fullKey, res, item
      if (!data && types.length === 0) {
        return true
      }
      res = keys.every(k => {
        item = data[k]
        fullKey = `${isArray ? prefix : prefix + k}`
        if (Array.isArray(item)) {
          // 处理array
          let isArrayPrimite = typeof item[0] !== 'object' && item[0]
          let validate = this.getValidateResult(this.getTypeValue(this.type[fullKey.replace(/\.$/, '')]).sup === item.constructor, item, k, fullKey)
          if (!validate) return false
          return validateHandler.call(this, item, `${fullKey}${isArrayPrimite ? '[0]' : '[0].'}`, true)
        } else if (item && typeof item === 'object' && !(item instanceof Date)) {
          // 处理object
          let validate = this.getValidateResult(this.getTypeValue(this.type[fullKey.replace(/\.$/, '')]).sup === item.constructor, item, k, fullKey)
          if (!validate) return false
          return validateHandler.call(this, item, `${fullKey}${isArray ? '' : '.'}`)
        } else if (item == void 0) {
          // 处理null或者undefined
          if (!this.getTypeValue(this.type[fullKey]).required) return true
          return this.getValidateResult(this.getTypeValue(this.type[fullKey]).sup === item, item, k, fullKey)
        } else {
          return this.getValidateResult(this.getTypeValue(this.type[fullKey]).sup === item.constructor, item, k, fullKey)
        }
      })
      if (!res) {
        this.throw(fullKey, this.type[fullKey], item.constructor)
        return res
      }
      let validateKeys = Object.keys(this.validateType).filter(key => this.validateType[key].required && !this.filterKey.includes(key))
      if (!validateKeys.length) return res
      console.error(`入参字段${validateKeys.toString()} 应该是必填项，但是未发现传参，请查看接口工具定义是否正确！`)
      return false
    }
  }
  getValidateResult (conditional, item, k, fullKey) {
    let res = this.filterKey.includes(k) || conditional
    if (res) {
      delete this.validateType[fullKey]
    }
    return this.filterKey.includes(k) || conditional
  }
  getType(sup) {
    return (sup.toString().match(/function\s*(\w+)\s*\(\)/) || [])[1]
  }
  getTypeValue(val) {
    if (!val) return false
    let isObject = this.getType(typeof val !== 'function' ? val.constructor : val) === 'Object', required = isObject ? val.required : false, sup = isObject ? val.type : val
    return {
      required,
      sup
    }
  }
  throw(key, sup, cur) {
    if (!sup) {
      key = key.replace(/(\.)$/, '')
      sup = typeof this.type[key] === 'string' ? {
        type: this.type[key],
        required: false
      } : this.type[key]
    }
    // 字段不存在
    if (!sup) {
      console.error(`接口地址：${this.url}\n 请求方式：${this.method}\n 错误描述：字段 ${key} 定义不存在`)
      return
    }
    if (this.getType(sup.type || sup) === this.getType(cur)) return
    console.error(`接口地址：${this.url}\n 请求方式：${this.method}\n 错误描述：字段 ${key} 类型错误，期待得到类型 ${this.getType(sup.type || sup)},现在为 ${this.getType(cur)}`)
  }
  isPrimitive(value) {
    return (
      typeof value === 'string' ||
      typeof value === 'number' ||
      // $flow-disable-line
      typeof value === 'symbol' ||
      typeof value === 'boolean'
    )
  }
}
