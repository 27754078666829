<template>
  <div class="iv-user-info">
    <div class="user-info-content">
      <div class="user-info-avatar">
        <img class="avatar" :src="photoSrc" @error="nofind" alt="avatar"/>
      </div>
      <div class="user-information">
        <div class="user-info">
          <div class="user-info-name">{{ userInfo.userName }}</div>
          <div class="user-info-job-number">{{ userInfo.userInfoId }}</div>
        </div>
        <div class="user-info-polity">
          <div>{{ getPolity() }}</div>
        </div>
      </div>
    </div>
    <div class="user-tissue">
      <div class="iv-img-tu iv-text-center">
        <Poptip trigger="hover" placement="bottom">
          <img v-if="userInfo.djPartyDuty" class="iv-pointer" src="@/assets/images/new-index/dang.png" alt="">
          <div class="iv-user-info-header-organ-content" slot="content">
            <p v-for="(item, index) in userInfo.djPartyDuty" :key="index" class="user-info-pop">
              <img class="iv-pointer pop-avatar" src="@/assets/images/index/dang.png" alt="">
              <span class="iv-fs-14">{{ item }}</span>
            </p>
          </div>
        </Poptip>
        <Poptip trigger="hover" placement="bottom">
          <img v-if="userInfo.gjPartyDuty" class="iv-pointer" src="@/assets/images/new-index/gong.png" alt="">
          <div class="iv-user-info-header-organ-content" slot="content">
            <p v-for="(item, index) in userInfo.gjPartyDuty" :key="index" class="user-info-pop">
              <img class="iv-pointer pop-avatar" src="@/assets/images/index/hui.png" alt="">
              <span class="iv-fs-14">{{ item }}</span>
            </p>
          </div>
        </Poptip>
        <Poptip trigger="hover" placement="bottom">
          <img v-if="userInfo.tjPartyDuty" class="iv-pointer" src="@/assets/images/new-index/tuan.png" alt="">
          <div class="iv-user-info-header-organ-content" slot="content">
            <p v-for="(item, index) in userInfo.tjPartyDuty" :key="index" class="user-info-pop">
              <img class="pop-avatar iv-pointer" src="@/assets/images/index/tuan-new.png" alt="">
              <span class="iv-fs-14">{{ item }}</span>
            </p>
          </div>
        </Poptip>
      </div>
      <div class="duty-day-content" v-if="dutyDay !== '0'">今天是你入党的第<span class="day">{{ dutyDay }}</span>天
      </div>
    </div>
    <!--    <Row class-name="iv-user-info-icon">-->
    <!--      <Col class-name="iv-pointer iv-text-center iv-user-info-icon-col">-->
    <!--        <Iconfont type="jifen1" class="iv-tubiao" @click="viewPoints"></Iconfont>-->
    <!--        <span style="color: rgba(0,0,0,0.65)" class="iv-fs-16 iv-user-info-icon-dang"-->
    <!--              @click="viewPoints">个人积分</span>-->
    <!--      </Col>-->
    <!--      <Col class-name="iv-pointer iv-text-center iv-user-info-icon-col">-->
    <!--        <Iconfont type="gerenxinxi1" class="iv-tubiao" @click="toPersonalPage"></Iconfont>-->
    <!--        <span style="color: rgba(0,0,0,0.65)" class="iv-fs-16 iv-user-info-icon-dang"-->
    <!--              @click="toPersonalPage">个人信息</span>-->
    <!--      </Col>-->
    <!--    </Row>-->
    <Modal title="入会申请书" v-model="isShowModal" className="iv-user-info-modal">
      <section class="iv-user-info-modal-content iv-text-center">
        <p class="iv-fs-32 iv-fc-65">京东方科技集团股份有限公司总部工会</p>
        <h1 class="iv-fs-32 iv-fc-85 iv-m-t-24" style="font-weight: 500;">入会申请书</h1>
        <p class="iv-fs-16 iv-fc-65 iv-text-left iv-m-t-24 iv-user-info-modal-main">
          我自愿加入京东方科技集团股份有限公司总部工会，遵守工会章程，执行工会决议，积极参加工会活动，为把我国建设成为富强、民主、文明的社会主义国家而努力奋斗。</p>
        <div class="iv-text-right iv-user-info-modal-bg"> <!-- iv-active-bg -->
          <p class="iv-fs-16 iv-fc-65 iv-p-r-12 iv-p-t-28">申请人：<span>于祯</span></p>
          <p class="iv-fs-16 iv-fc-65 iv-m-t-16 iv-p-r-12">{{ timeFormat(new Date(), `yyyy年MM月dd日`) }}</p>
        </div>
      </section>
      <div slot="footer">
        <Button type="sure" @click="agree">同意</Button>
        <Button type="cancel" @click="refuse">拒绝</Button>
        <!-- <Button type="close" @click="refuse">关闭</Button> -->
      </div>
    </Modal>
    <Modal title="系统提示" size="small" v-model="pointsModal" className="iv-points-info-modal">
      <div class="iv-p-24 iv-text-center">
        <p class="iv-fs-16 iv-fc-65 iv-text-left">功能将在后期上线，敬请期待...</p>
        <img class="iv-m-t-8" style="height: 173px;" src="@/assets/images/index/no-points.png">
      </div>
      <div slot="footer">
        <Button type="close" @click="refusePointsModal">关闭</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/modal'
import {timeFormat} from '@/utils'
import Iconfont from '@/components/iconfont'

export default {
  name: 'userInfo',
  data() {
    return {
      isShowModal: false,
      userInfo: {},
      pointsModal: false, //个人积分弹框
      photoSrc: '',
      dutyDay: '0'
    }
  },
  mounted() {
    console.log("ceshi 人员信息")
    this.getShowDj()
    this.getUserOrganization()
  },
  methods: {
    getShowDj() {
      this.$ajax({
        url: 'api-platform/app/authorization/showDJ',
        method: 'get',
        headers: {
          'App-Code': 'dj'
        }
      }).then(({data}) => {
        if (data.data.show) {
          this.getDutyDay()
        }
      })
    },
    getDutyDay() {
      this.$ajax({
        url: 'api-djt/home/PartyMemberDays',
        method: 'get',
      }).then(res => {
        this.dutyDay = res.data.data;
      })
    },
    timeFormat,
    //获取头像
    getUserHeaderPhoto() {
      this.$ajax({
        url: 'api-uum/module/portal/user/getPhoto',
        method: 'get',
        data: {
          userCode: this.userInfo.userCode
        },
        responseType: 'arraybuffer'
      }).then((response) => {
        this.photoSrc = `data:image/png;base64,${btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`
      })
    },
    getUserOrganization() {
      this.$ajax({
        url: 'api-uum/uuminfo/getUserInfo',
        method: 'get',
        data: {}
      }).then(({data}) => {
        this.userInfo = data.data
        if (this.userInfo.djPartyDuty) {
          this.userInfo.djPartyDuty = this.userInfo.djPartyDuty.split(',')
        }
        if (this.userInfo.gjPartyDuty) {
          this.userInfo.gjPartyDuty = this.userInfo.gjPartyDuty.split(',')
        }
        if (this.userInfo.tjPartyDuty) {
          this.userInfo.tjPartyDuty = this.userInfo.tjPartyDuty.split(',')
        }
        sessionStorage.setItem('orgPath', this.$encode(this.userInfo.orgPath || ''))
        this.getUserHeaderPhoto()
      })
    },
    //个人积分
    viewPoints() {
      this.pointsModal = true
    },
    refusePointsModal() {
      this.pointsModal = false
    },
    //个人信息
    toPersonalPage() {
      this.$router.push({
        name: 'userInfo'
      })
    },
    //党员信息
    personPoints(type) {
      this.$router.push({
        name: type == 'YWX01' ? 'partyInfo' : (type == 'YWX04' ? 'leagueInfo' : 'fullInfo')
      })
    },
    //组织目标
    toOrgTargetPage() {
      // this.$router.push({
      //   name: 'orgTarget'
      // })
      this.pointsModal = true
    },
    //党员联系员工
    toPartyPersonPage() {
      // this.$router.push({
      //   name: 'contact'
      // })
      this.pointsModal = true
    },
    //入会申请
    showModal() {
      this.isShowModal = true
    },
    //同意
    agree() {
      this.isShowModal = false
    },
    //拒绝
    refuse() {
      this.isShowModal = false
    },
    getPolity() {
      // 1、有+有：正常显示
      // 2、有+无：仅显示：政治面貌
      // 3、无+有：仅显示：岗位信息
      // 4、无+无：无岗位信息
      if (!this.userInfo.polityName && !this.userInfo.positionName) {
        return '无岗位信息'
      } else if (!this.userInfo.polityName) {
        return this.userInfo.positionName
      } else if (!this.userInfo.positionName) {
        return this.userInfo.polityName
      } else {
        return this.userInfo.polityName + '  ' + this.userInfo.positionName
      }
    },
    nofind() {
      var img = event.srcElement
      img.src = require('@/assets/images/avatar/avatar-man.png')
      img.onerror = null
    }
  },
  components: {
    Modal,
    Iconfont
  }
}
</script>

<style lang="scss" scoped>
.iv-user-info {
  width: 469px;
  height: 206px;
  background-image: linear-gradient(to bottom, #FEF2EE 10%, #fff 90%);
  border-radius: 8px;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.150459);
  padding: 40px 0 0 40px;

  .user-tissue {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }

  .duty-day-content {
    padding-left: 16px;
    color: #192D3A;
    font-size: 16px;

    .day {
      color: #D7161C;
    }
  }

  .user-info-content {
    display: flex;
    align-items: center;

    .user-info-avatar {
      width: 93px;
      height: 93px;
      border-radius: 100%;
      overflow: hidden;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .user-information {
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      width: 360px;
    }

    .user-info {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .user-info-name {
        font-size: 20px;
        color: #192D3A;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 300px;
      }

      .user-info-job-number {
        font-size: 16px;
        color: #7F8080;
        align-self: flex-end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 300px;
      }
    }

    .user-info-polity {
      font-size: 16px;
      color: #192D3A;
    }
  }

  .iv-user-info-header {
    height: 148px;
    background-color: #fff;
    width: 148px;
    margin: auto;
    border-radius: 50%;
    // background-image: url('~@/assets/images/user-bg.png');
    .iv-user-info-header-top {
      width: 138px;
      height: 138px;
      border-radius: 50%;
      margin: 5px;
    }
  }

  .iv-user-info-header-userName {
    display: flex;
    align-items: center;
    justify-content: center;

    .iv-code {
      font-family: 'Microsoft YaHei';
      font-weight: 400;
    }

    .iv-user-info-header-organ {
      height: 18px;
      margin-top: -4px;

      img {
        width: 18px;
        height: 18px;
      }

      .iv-user-info-header-organ-content {
        padding: 8px 8px;

        p {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .iv-img-tu {
    width: 93px;

    img {
      margin: 0 5px;
    }
  }

  .iv-user-info-grey {
    padding: 16px 0;
    background-color: #F5F7FA;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei';
    height: 56px;
  }

  .iv-user-info-tag-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);

    .iv-user-info-tag {
      width: 162px;
      height: 40px;
      text-align: center;
      font-size: 16px;
      line-height: 40px !important;
      margin: 0;
      border-radius: 8px;

      /deep/ .ivu-tag-text {
        color: rgba(0, 0, 0, 0.65) !important;
      }
    }
  }

  .iv-user-info-icon {
    border-radius: 8px;
    margin-top: 18px;
    height: 80px;

    .iconfont {
      font-size: 36px;
    }

    .iv-user-info-icon-col {
      // display: flex;
      // align-items: center;
      height: 80px;
      border-radius: 8px;
      background: #F5F7FA;
      line-height: 80px;
      width: 210px;

      &:first-child {
        // border-right: 1px dashed rgba(0, 0, 0, 0.146935);
        float: left;
      }

      &:last-child {
        justify-content: flex-start;
        float: right;
      }

      .iv-tubiao {
        color: #2373C8;
        font-size: 36px;
        vertical-align: -8px;
      }

      img {
        width: 52px;
        height: 52px;
      }

      .iv-user-info-icon-dang {
        font-size: 16px;
        margin-left: 15px;
      }
    }
  }
}

.iv-user-info-modal {
  .iv-user-info-modal-content {
    padding: 48px 20px 24px 24px;

    .iv-user-info-modal-main {
      text-indent: 2em;
    }

    .iv-user-info-modal-bg {
      height: 94px;
      margin-top: 64px;
    }

    .iv-active-bg {
      background: url('~@/assets/images/index/chuo.png') no-repeat 98% center;
    }
  }
}

.user-info-pop {
  display: flex;
  align-items: center;
  justify-content: center;

  .pop-avatar {
    width: 16px;
  }
}
</style>
