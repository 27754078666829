<script>
export default {
  name: "Schedule",
  data() {
    return {
      scheduleList: [],
      isDuty: false,
      scheduleDialogVisible: false,
      formInline: null
    }
  },
  computed: {
    day() {
      return new Date().getDate()
    },
    date() {
      const now = new Date()
      // 获取当前年份
      const year = now.getFullYear();
      // 获取当前月份（0-11，所以加1以符合常规的1-12表示）
      const month = now.getMonth() + 1;
      return `${year}年${month}月`
    }
  },
  methods: {
    getIcon: item => {
      return item.status === 1
          ? require('@/assets/images/schedule/underWay.jpg')
          : require('@/assets/images/schedule/completed.jpg');
    },
    getShowDj() {
      this.$ajax({
        url: 'api-platform/app/authorization/showDJ',
        method: 'get',
        headers: {
          'App-Code': 'dj'
        }
      }).then(({data}) => {
        if (data.data.show) {
          this.isDuty = true
          this.getScheduleList(new Date())
        }
      })
    },
    getScheduleList(date) {
      const params = {date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}
      this.$ajax({
        url: '/api-djt/home/schedule/dayList',
        method: 'GET',
        data: params
      }).then(res => {
        console.info(res.data, 'list')
        this.scheduleList = res.data.data;
      });
    },
    openSchedule(id) {
      this.$ajax({
        method: 'get',
        url: `/api-djt/home/schedule/info/${id}`
      }).then(res => {
        this.formInline = res.data.data
        this.scheduleDialogVisible = true
      })
    }
  },
  mounted() {
    this.getShowDj();
  }
}
</script>

<template>
  <div class="schedule">
    <div class="schedule-title">日程</div>
    <div class="schedule-content">
      <div class="calender-content">
        <div class="calender-bg">{{ day }}</div>
        <div class="calender-date">{{ date }}</div>
      </div>
      <div class="schedule-info no-scrollbar" v-if="isDuty && scheduleList.length > 0">
        <div class="schedule-item " v-for="item in scheduleList" :key="item.id" @click="openSchedule(item.id)">
          <div class="item-title">
            <img :src="getIcon(item)" class="item-status-icon"/>
            <span class="item-status-name">{{
                item.status === 1 ? "进行中" : "已完成"
              }}</span>
            <Icon type="arrow-right-b" size="16" class="item-arrow"></Icon>
          </div>
          <div class="item-content">
            <img :src="item.icon" alt="" class="item-content-icon"/>
            <div class="content-info">
              <div class="info-title">{{ item.title }}</div>
              <div class="info-time">{{ item.remindTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="not-duty">
        <img src="@/assets/images/schedule/not-duty.png" alt="" width="275" height="59">
      </div>
    </div>
    <Modal
        v-model="scheduleDialogVisible"
        :mask-closable="false"
        title="查看日程">
      <Form class="form" ref="formInline" :model="formInline" :label-width="96">
        <FormItem label="标题：">
          {{ formInline?.title }}
        </FormItem>
        <FormItem label="提醒时间：">
          {{ formInline?.remindTime }}
        </FormItem>
        <FormItem label="备注：">
          {{ formInline?.remarks }}
        </FormItem>
        <FormItem label="参与人：">
          {{
            (formInline?.participantList || [])
                .map(item => item.chineseName)
                .join(",")
          }}
        </FormItem>
      </Form>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
.schedule {
  width: 469px;
  height: 206px;
  background: #fff;
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.150459);
  display: flex;
  flex-direction: column;

  .schedule-title {
    height: 54px;
    padding-left: 30px;
    line-height: 54px;
    color: #140707;
    font-size: 16px;
    border-bottom: 1px solid #D1D1D1;
    flex-shrink: 0;
    font-weight: 500;
  }

  .schedule-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }

  .calender-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .calender-bg {
    width: 46px;
    height: 42px;
    background: url("~@/assets/images/schedule/calender-bg.png") no-repeat center center;
    background-size: contain;
    text-align: center;
    padding-top: 15px;
    color: #40A9FF;
    font-size: 20px;
    font-weight: bold;

  }

  .calender-date {
    color: #7F8080;
    font-size: 12px;
    margin-top: 4px;
  }

  .not-duty {
    margin: -24px 0 0 48px;
  }

  .schedule-info {
    margin-left: 16px;
    width: 330px;
    height: 90px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
  }

  .schedule-item {
    background: #f8f8f8;
    width: 330px;
    height: 90px;
    border-radius: 8px;
    padding: 10px 0 0 24px;
    flex-shrink: 0;
    cursor: pointer;

    .item-title {
      display: flex;
      align-items: center;
    }

    .item-status-icon {
      width: 20px;
      height: 20px;
    }

    .item-status-name {
      margin-left: 4px;
      font-size: 14px;
    }

    .item-arrow {
      margin-left: 4px;
    }

    .item-content {
      display: flex;
      align-items: center;
      padding-left: 8px;
      margin-top: 12px;
    }

    .item-content-icon {
      flex-shrink: 0;
      width: 31px;
      height: 31px;
    }

    .content-info {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 8px;
      overflow: hidden;
      font-size: 14px;
    }

    .info-title {
      color: #1b1b1b;
    }

    .info-time {
      color: #7f8080;
    }
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    scrollbar-width: none;
  }
}

.form {
  padding-right: 20px;
  box-sizing: border-box;
}
</style>
