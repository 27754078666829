<template>
  <div class="iv-m-t-16 iv-home-tab" style="width: 100%;height: 332px">
    <Block :left="2" :size="20" :headerWidth="130" type="sign" :imgBorder="'zizhu1'" :headerImg="'zizhu'" title="项目申报" class="col iv-p-b-20" @on-more="toMorePage('xmsb')">
      <div class="iv-pos-r">
        <ul v-if="list.length > 0" class="iv-home-tab-center">
          <li v-for="(item, index) in list" :key="index" class="iv-home-tab-center-li iv-m-t-16">
            <p class="iv-fs-16 iv-m-t-16">
              <span class="iv-fs-16 iv-fc-65">[{{map[item.type]}}]</span>
              <span class="iv-fs-16 iv-fc-85 iv-home-tab-center-main iv-pointer iv-hover-color" @click="toDetailPage(item, 'xmsb')" :title="item.title">{{item.title}}</span>
              <span class="iv-fs-14 iv-fc-45 iv-home-tab-center-time">{{timeFormat(item.time)}}</span>
            </p>
          </li>
        </ul>
        <NoData v-if="list.length <= 0"></NoData>
        <Spin v-if="spinShow" fix></Spin>
      </div>
    </Block>
    <Block :left="4" :headerWidth="130" :size="15" type="yiqishenqing" title="评优申报" :imgBorder="'zizhu1'" :headerImg="'zizhu'" class="col iv-p-b-20" @on-more="toMorePage('pysb')">
      <div class="iv-pos-r">
        <ul v-if="list.length > 0" class="iv-home-tab-center">
          <li v-for="(item, index) in pyList" :key="index" class="iv-home-tab-center-li iv-m-t-16">
            <p class="iv-fs-16 iv-m-t-16">
              <span class="iv-fs-16 iv-fc-65">[{{map[item.type]}}]</span>
              <span class="iv-fs-16 iv-fc-85 iv-home-tab-center-main iv-pointer iv-hover-color" @click="toDetailPage(item, 'pysb')" :title="item.title">{{item.title}}</span>
              <span class="iv-fs-14 iv-fc-45 iv-home-tab-center-time">{{timeFormat(item.time)}}</span>
            </p>
          </li>
        </ul>
        <NoData v-if="list.length <= 0"></NoData>
        <Spin v-if="spinShow" fix></Spin>
      </div>
    </Block>
  </div>
</template>

<script>
import { timeFormat, getToken } from '@/utils'
import NoData from '@/components/no-data'
import Block from '@/components/block'
import {listApplication} from './interface'

export default {
  data () {
    return {
      list: [],
      pyList: [],
      spinShow: false,
      timerIds: []
    }
  },
  created () {
    this.map = {
      'YWX01': '党建',
      'YWX03': '工会',
      'YWX04': '青团',
      'YWX05': '文化',
      'YWX06': 'CCO',
      'YWX02': '纪检'
    }
    this.project()
    this.search()
  },
  methods: {
    timeFormat,
    getCoverImage (photo) {
      if (photo) {
        return `api-uum/file/downloadFile?fileId=${photo}&access_token=${getToken()}`
      } else {
        return require('@/assets/images/course/cover-image-1.png')
      }
    },
    //图片报错显示默认图片
    setDefaultImg (e) {
      e.target.src = require('@/assets/images/course/cover-image-1.png')
    },
    //跳转详情
    toDetailPage (item, type) {
      this.$router.push({
        name: type == 'xmsb' ? 'projectDetail' : 'appraisingDetail',
        query: {
          applicationInfoId: this.$encode(item.applicationInfoId),
          backUrl: 'index',
          bizLineCode: this.$encode(item.bizLineCode || '')
        }
      })
    },
    //评优
    search () {
      this.spinShow = true
      listApplication({
        bizTypeCode: 'YWLX01',
        infoState: 'SBZT02',
        pageSize: 6
      }).then(({data}) => {
        this.pyList = data.map(item => ({
          ...item,
          title: item.applicationName,
          time: item.publishTime,
          type: item.bizLineCode
        }))
        this.spinShow = false
      })
    },
    //项目
    project () {
      listApplication({
        bizTypeCode: 'YWLX02',
        infoState: 'SBZT02',
        pageSize: 6
      }).then(({data}) => {
        this.list = data.map(item => ({
          ...item,
          title: item.applicationName,
          time: item.publishTime,
          type: item.bizLineCode
        }))
        this.spinShow = false
      })
    },
    //更多
    toMorePage (type) {
      // this.$Message.info("功能暂未上线，敬请期待！")
      //上线后
      this.$router.push({
        name: type == 'xmsb' ? 'projectList' : 'appraisingList'
      })
    }
  },
  components: {
    NoData,
    Block
  }
}
</script>

<style lang="scss" scoped>
.iv-home-tab {
  // background: #fff;
  border-radius: 16px;
  // padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: row;
  .col {
    flex: 1;
    &:first-child {
      margin-left: unset;
    }
    margin-left: 16px;
  }
  .iv-home-tab-center {
    width: 100%;
    overflow-x: hidden;
    margin-top: 8px;
    .iv-home-tab-center-li {
      position: relative;
      .iv-home-tab-center-main {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        // width: 330px;
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
      }
      .iv-home-tab-center-time {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
