<template>
  <div class="iv-m-t-16 iv-news-center-parent" style="width: 100%;height: 332px;">
    <!-- <Tab :navList="tabs" @on-click="tabClick">
      <span @click="toMorePage" class="iv-fs-14 iv-fc-25 iv-pointer" slot="extra">更多 ></span>
    </Tab> -->
    <div class="tab-title" style="position: relative;">
      <span class="ib-border">
        <Iconfont class="" :type="'zhiduchaxun'"></Iconfont>
      </span>
      <span class="ib-title">牡丹动态</span>
      <ul>
        <li :class="currentName == item.name ? 'actived' : ''" @click="tabClick(item.name)"  v-for="(item, index) in tabs" :key="index">{{item.label}}</li>
      </ul>
      <div @click="toMorePage" class="iv-pull-right iv-font" style="margin-right: 16px;cursor: pointer;">
        更多
        <iconfont class="icon iconfont icon-plus-square"></iconfont>
      </div>
      <!-- <img style="height: 22px;" class="iv-pull-right" :src="`${require('@/assets/images/new-index/tss.png')}`" /> -->
    </div>
    <div class="iv-news-center iv-p-t-8">
      <ul v-if="list.length > 0">
        <li class="iv-news-center-li iv-m-t-16" v-for="(item, index) in list" :key="index" @click="todetail(item)">
          <p class="news-item__wrapper iv-fs-16 iv-fc-65 iv-m-t-8">
            <span class="iv-fs-16 iv-fc-85 iv-news-center-main">
              <span class="iv-m-r-8 iv-fc-65" v-if="item.orgName">[{{item.orgName}}]</span>
              <span class="iv-hover-color" :title="item.title">{{item.title}}</span>
            </span>
            <Iconfont v-if="item.top > 0" type="bjgbjya-zhiding2" class="iv-news-center-icon"></Iconfont>
            <span class="iv-fs-14 iv-fc-45 iv-news-center-time">{{timeFormat(item.publishTime)}}</span>
          </p>
        </li>
      </ul>
      <NoData class="iv-news-cen-nodata" v-if="list.length <= 0"></NoData>
      <!-- 轮播图 -->
      <div class="iv-news-center-carousel">
        <Swiper v-if="carouselList.length" class="iv-carou" ref="mySwiper" :options="swiperOptions">
          <SwiperSlide class="iv-swiper-wrapper" v-for="(v, k) in carouselList" :key="k">
            <img class="iv-swiper-item iv-pointer" :src="getImgPath(v.imageUrl)" @click="toImgdetail(v)" />
            <p class="iv-swiper-item-text">{{v.name}}</p>
          </SwiperSlide>
          <div class="swiper-pagination iv-text-right" slot="pagination"></div>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { timeFormat, getToken } from '@/utils'
import Tab from '@/components/tab'
import NoData from '@/components/no-data'
import Iconfont from '@/components/iconfont'
// swiper
import { Swiper as SwiperClass, Pagination, Autoplay } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
SwiperClass.use([Pagination, Autoplay])
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: 'newsCenter',
  data () {
    return {
      list: [],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        }
      },
      carouselList: [],
      currentName: 'boe',
      cmsUrl: ''
    }
  },
  created () {
    this.map = {
      1: '党建',
      2: '工会',
      3: '青团',
      4: '文化',
      // 5: 'CCO'
    }
    this.jumpUrl = {
      'boe': `homePage?access_token=${getToken()}`,
      'party_lead': `party_lead?categoryCode=party_lead&categoryName=%E5%85%9A%E5%BB%BA%E5%BC%95%E9%A2%86&categoryId=36&categoryType=1&access_token=${getToken()}`,
      'manager_idea': `manager_idea?categoryCode=manager_idea&categoryName=%E7%AE%A1%E7%90%86%E8%80%85%E6%80%9D%E6%83%B3&categoryId=71&categoryType=1&access_token=${getToken()}`,
      'xwzbj': `xwzbj?categoryCode=xwzbj&categoryName=BEZ%E5%BE%AE%E8%AE%AF&categoryId=146&categoryType=1&access_token=${getToken()}`,
      'model_strength': `model_strength?categoryCode=model_strength&categoryName=%E6%A6%9C%E6%A0%B7%E5%8A%9B%E9%87%8F&categoryId=36&categoryType=1&access_token=${getToken()}`
    }
    this.initTabs()
    this.cmsUrl = sessionStorage.getItem('cmsUrl')
    this.loop()
  },
  methods: {
    timeFormat,
    async loop () {
      if (!this.cmsUrl) {
        setTimeout(() => {
          this.cmsUrl = sessionStorage.getItem('cmsUrl')
          this.loop()
        }, 500)
      } else {
        this.getCarouselList()
        this.list = await this.getDataByBOERequest('boe', 6)
      }
    },
    getImgPath (imageUrl) {
      return this.cmsUrl + '/prod-api' + imageUrl
    },
    //获取轮播图
    async getCarouselList () {
      this.carouselList = await this.getDataByBOERequest('pic_news', 5)
    },
    //boe数据请求接口
    getDataByBOERequest (code, rowNum) {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: `${this.cmsUrl}/api-qixuan/cms/front/getHome`,
          method: 'get',
          data: {
            code: code,
            rowNum: rowNum
          }
        }).then(({data}) => {
          const res = data.data[code].map(val => ({
            ...val,
            title: val.title ? val.title.replaceAll('%26', '&') : '',
            cmstype: code
          }))
          resolve(res || [])
        })
      })
    },
    //tab签点击事件
    async tabClick (name) {
      this.currentName = name
      //文化宣传 管理者思想、重大新闻、新闻直播间、党建引领
      this.list = await this.getDataByBOERequest(name, 6)
    },
    //点击更多
    toMorePage () {
      const href = `${sessionStorage.getItem('jumpCmsUrl')}/#/${this.jumpUrl[this.currentName]}`
      window.open(href, '_blank')
    },
    todetail (item) {
      const href = `${sessionStorage.getItem('jumpCmsUrl')}/#/newsDetail?infoId=${item.id}&categoryName=${item.level1Column.name}&access_token=${getToken()}`
      window.open(href, '_blank')
    },
    //轮播图详情
    toImgdetail (item) {
      // 如果是栏目的不进行栏目跳转
      if (item.forwardType == 1) {
        return
        // const href = `${sessionStorage.getItem('jumpCmsUrl')}/#/columns?categoryId=${item.columnId}&access_token=${getToken()}`
        // window.open(href, '_blank')
      }
      if(item.forwardId){
        const href = `${sessionStorage.getItem('jumpCmsUrl')}/#/newsDetail?infoId=${item.forwardId}&access_token=${getToken()}&categoryName=${item.level1Column.name}`
        window.open(href, '_blank')
      }
    },
    initTabs () {
      const arr = [{
        title: '文化宣传',
        icon: 'boe'
      },{
        title: '党建引领',
        icon: 'party_lead'
      },{
        title: '战略前沿',
        icon: 'manager_idea'
      }, {
        title: '榜样力量',
        icon: 'model_strength'
      }]
      this.tabs = arr.map(item => ({
        // label: (h) => {
        //   return h('div', [
        //     h(Iconfont, {
        //       props: {
        //         type: item.icon
        //       },
        //       style: 'margin-right: 10px'
        //     }),
        //     h('span', item.title)
        //   ])
        // },
        label: item.title,
        name: item.icon
      }))
      console.log("tabs",this.tabs)
    }
  },
  components: {
    Tab,
    NoData,
    Iconfont,
    Swiper,
    SwiperSlide
  }
}
</script>

<style lang="scss" scoped>
.iv-news-center-parent {
  background: #fff;
  border-radius: 8px;
  padding: 0 16px 30px;
  box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.150459);
  .ivu-tabs {
    margin: 0 -16px 0;
    padding: 0 16px 0;
  }
  .tab-title {
    height: 55px;
    margin: 0 -16px 0;
    border-bottom: 1px solid #D9D9D9;
    background-image: url('~@/assets/images/new-index/bt1.png');
    background-repeat: no-repeat;
    background-position-x: -2px;
    ul {
      display: flex;
      justify-content: flex-start;
      margin-left: 211px;
      li {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
        margin-right: 20px;
        margin-top: 11px;
        width: 88px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          background: #2373C8;
          border-radius: 16px;
          color: #fff;
        }
      }
      .actived {
        background: #2373C8;
        border-radius: 16px;
        color: #fff;
      }
      li:last-child {
        margin-right: 0px;
      }
    }
    img {
      margin-right: 80px;
      margin-top: -28px;
      width: 192px;
    }
    .iv-font {
      margin-top: -28px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      .iconfont {
        vertical-align: -1px;
      }
    }
  }
  .ib-border {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: #2373C8;
    z-index: 1;
    left: 16px;
    top: 16px;
    .iconfont {
      color: #fff;
      margin-left: 5px;
      vertical-align: -4px;
      font-size: 15px;
    }
  }
  .ib-title {
    position: absolute;
    top: 18px;
    left: 54px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: rgba(0,0,0,0.85);
  }
}
.iv-news-center {
  position: relative;
  .iv-news-center-li {
    position: relative;
    height: 24px;
    cursor: pointer;
    .iv-news-center-main {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      // width: 660px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 390px;
    }
    .iv-news-center-icon {
      position: absolute;
      right: 500.56px;
      top: 50%;
      transform: translateY(-50%);
      color: #2373C8;
    }
    .iv-news-center-time {
      position: absolute;
      right: 410.56px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .iv-news-cen-nodata {
    width: calc(100% - 355.56px);
  }
  .iv-news-center-carousel {
    width: 360px;
    height: 203px;
    position: absolute;
    right: 0;
    top: 35px;
    overflow: hidden;
    .iv-swiper-item {
      width: 100%;
      height: 203px;
    }
    .iv-swiper-item-text {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      background: rgba(0, 0, 0, 0.45);
      margin-top: -40px;
      padding-left: 16px;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    /deep/ .swiper-pagination {
      bottom: 8px;
      padding-right: 10px;
    }
    /deep/ .swiper-pagination-bullet {
      // background: #fff;
      // opacity: 1;
      background-color:rgba(0, 0, 0, 0.5);
    }
    /deep/ .swiper-pagination-bullet-active {
      width: 20px;
      height: 8px;
      background: #2373C8;
      border-radius: 4px;
    }
  }
}
</style>
