<template>
  <div class="iv-m-t-16 iv-home-tab" style="width: 100%;height: 332px">
    <Block :left="3" :size="18" type="huodong" title="精彩活动推荐" :imgBorder="'zizhu'" :headerImg="'zizhu'" class="col" @on-more="toMorePage('hdbm')">
      <div class="iv-pos-r">
        <ul v-if="cardList.length > 0" class="iv-home-tab-center" :class="{'iv-home-tab-activity': currentName == 'hdbm'}">
          <li v-for="(item, index) in cardList" :key="index" class="iv-home-tab-center-li">
            <p v-if="currentName != 'hdbm'" class="iv-fs-16 iv-fc-65 iv-m-t-8">
              <span class="iv-fs-16 iv-fc-45">[{{map[item.type]}}]</span>
              <span class="iv-fs-16 iv-fc-85 iv-home-tab-center-main iv-pointer iv-hover-color" @click="toDetailPage(item)">{{item.title}}</span>
              <span class="iv-fs-14 iv-fc-45 iv-home-tab-center-time">{{timeFormat(item.time)}}</span>
            </p>
            <dl v-else class="iv-home-tab-center-dl" @click="toActivityDetailPage(item)">
              <dt class="iv-home-tab-center-dl-img">
                <img :src="getCoverImage(item.eventCoverFileId)" @error="setDefaultImg" alt="">
                <span v-if="item.isPinOnTop == '1'" class="iv-activity-content-item-img-zhiding">置顶</span>
                <p
                  class="iv-home-tab-center-dl-img-tag iv-text-center"
                  :class="{'iv-home-tag-orange': Date.now() >= item.applicationDeadline && item.infoState == 'SBZT02', 'iv-home-tag-transparent': item.infoState == 'SBZT04', 'iv-home-tag-pink': item.infoState == 'SBZT02' && Date.now() < item.applicationDeadline}"
                >
                  {{item.tagName}}
                </p>
              </dt>
              <dd class="iv-home-tab-center-dl-text">
                <h3 class="application-name iv-fc-65 iv-fs-16" :title="item.applicationName">{{item.applicationName}}</h3>
                <p class="publish-org__name iv-fs-16 iv-fc-45 iv-m-t-16" :title="item.publishOrgName">{{item.publishOrgName}}</p>
              </dd>
            </dl>
          </li>
        </ul>
        <NoData v-if="cardList.length <= 0"></NoData>
        <Spin v-if="spinShow" fix></Spin>
      </div>
    </Block>
  </div>
</template>

<script>
import Tab from '@/components/tab'
import { timeFormat, getToken } from '@/utils'
import NoData from '@/components/no-data'
import Iconfont from '@/components/iconfont'
import Block from '@/components/block'
import {listApplication} from './interface'
import _ from 'lodash'

export default {
  data () {
    return {
      list: [],
      cardList: [],
      tabs: [],
      currentName: 'hdbm',
      spinShow: false,
      timerIds: []
    }
  },
  created () {
    this.map = {
      'YWX01': '党建',
      'YWX03': '工会',
      'YWX04': '青团',
      'YWX05': '文化',
      'YWX06': 'CCO',
      'YWX02': '纪检'
    }
    this.getListDetails()
  },
  methods: {
    timeFormat,
    getCoverImage (photo) {
      if (photo) {
        return `api-uum/file/downloadFile?fileId=${photo}&access_token=${getToken()}`
      } else {
        return require('@/assets/images/course/cover-image-1.png')
      }
    },
    //图片报错显示默认图片
    setDefaultImg (e) {
      e.target.src = require('@/assets/images/course/cover-image-1.png')
    },
    //跳转详情
    toDetailPage (item) {
      let query = {}
      const name = this.currentName == 'xmsb' ? 'projectDetail' : (this.currentName == 'pysb' ? 'appraisingDetail' : 'activitySingle')
      if (this.currentName == 'xmsb' || this.currentName == 'pysb') {
        query = {
          applicationInfoId: this.$encode(item.applicationInfoId),
          backUrl: 'index',
          bizLineCode: this.$encode(item.bizLineCode || '')
        }
      } else {
        query = {
          id: this.$encode(item.eventInfoId),
          eventListId: this.$encode(item.eventListId),
          type: this.$encode(item.reportState == 'BMZT02' ? 'see' : 'add'),
          backUrl: 'index'
        }
      }
      this.$router.push({
        name: name,
        query: query
      })
    },
    //活动详情
    toActivityDetailPage (item) {
      const isView = item.applicationDeadline - Date.now() < 0 && item.infoState === 'SBZT02'
      this.$router.push({
        name: 'activityDetail',
        query: {
          applicationInfoId: item.applicationInfoId,
          isSignUp: item.isSignUp === false ? 'no' : 'yes',
          isView
        }
      })
    },
    //评优
    search () {
      this.spinShow = true
      listApplication({
        bizTypeCode: 'YWLX01',
        infoState: 'SBZT02',
        pageSize: 6
      }).then(({data}) => {
        this.pyList = data.map(item => ({
          ...item,
          title: item.applicationName,
          time: item.publishTime,
          type: item.bizLineCode
        }))
        this.spinShow = false
      })
    },
    //项目
    project () {
      listApplication({
        bizTypeCode: 'YWLX02',
        infoState: 'SBZT02',
        pageSize: 6
      }).then(({data}) => {
        this.list = data.map(item => ({
          ...item,
          title: item.applicationName,
          time: item.publishTime,
          type: item.bizLineCode
        }))
        this.spinShow = false
      })
    },
    //更多
    toMorePage (arg) {
      // this.$Message.info("功能暂未上线，敬请期待！")
      //上线后
      const name = arg == 'xmsb' ? 'projectList' : (this.currentName == 'pysb' ? 'appraisingList' : 'activityList')
      this.$router.push({
        name: name
      })
    },
    //获取活动报名
    getListDetails () {
      this.list = []
      this.$ajax({
        url: 'api-general/module/v2event/listApplicationInfo',
        method: 'get',
        data: {
          bizTypeCode: 'YWLX03',
          infoState: 'SBZT02',
          pageSize: 4
        }
      }).then(({data}) => {
        this.list = data.data.map((item) => {
          const tagName = this.calculateDeadline(item)
          return {
            ...item,
            tagName
          }
        })
        this.$nextTick(() => {
          this.watchView()
        })
        this.spinShow = false
      })
    },
    //计算截止时间
    calculateDeadline (item) {
      if (!item.applicationDeadline) return
      if (item.infoState == 'SBZT04') {
        return '已结束'
      } else {
        const info = this.createTimer(item)
        return info
      }
    },
    createTimer (item) {
      const currentDate = Date.now()
      const distance = item.applicationDeadline - currentDate
      let info = ''
      if (distance < 0 && item.infoState == 'SBZT02') {
        info = '报名截止 活动进行中'
      }
      if (distance >= 0 && item.infoState == 'SBZT02') {
        //天
        const day = Math.floor(distance / (1000 * 60 * 60 * 24))
        //时
        const hour = Math.floor((distance / (1000 * 60 * 60)) % 24)
        //分
        const minutes = Math.floor((distance / (1000 * 60)) % 60)
        info = `还剩${day ? day + '天' : ''}${hour ? hour + '时' : ''}${minutes ? minutes + '分' : ''}`
      }
      return info
      // return new Promise((resolve, reject) => {
      //   const timerId = setInterval(() => {
      //     const currentDate = Date.now()
      //     const distance = new Date('2022-9-26').getTime() - currentDate
      //     let info = ''
      //     if (distance < 0 && item.infoState == 'SBZT02') {
      //       info = '报名截止 活动进行中'
      //     }
      //     if (distance >= 0 && item.infoState == 'SBZT02') {
      //       //天
      //       const day = Math.floor(distance / (1000 * 60 * 60 * 24))
      //       //时
      //       const hour = Math.floor((distance / (1000 * 60 * 60)) % 24)
      //       //分
      //       const minutes = Math.floor((distance / (1000 * 60)) % 60)
      //       info = `还剩${day}天${hour}时${minutes}分`
      //     }
      //     resolve(info)
      //   }, 1000 * 60)
      //   this.timerIds.push(timerId)
      // })
    },
    //tab签点击事件
    tabClick (name) {
      this.spinShow = true
      this.currentName = name
      if (name == 'pysb') {
        this.search()
      } else if (name == 'hdbm') {
        this.getListDetails()
      } else {
        this.project()
      }
    },
    initTabs () {
      const arr = [{
        title: '评优申报',
        icon: 'yiqishenqing',
        name: 'pysb'
      },
      {
        title: '活动报名',
        icon: 'youxuan',
        name: 'hdbm'
      },
      {
        title: '项目申报',
        icon: 'sign',
        name: 'xmsb'
      }]
      this.tabs = arr.map(item => ({
        label: (h) => {
          return h('div', [
            h(Iconfont, {
              props: {
                type: item.icon
              },
              style: 'margin-right: 10px'
            }),
            h('span', item.title)
          ])
        },
        name: item.name
      }))
    },
    watchView() {
      const vWidth = document.documentElement.clientWidth
      if (vWidth < 1366) {
        this.cardList = this.list.slice(0, 3)
      } else {
        this.cardList = this.list.slice(0, 4)
      }
    }
  },
  components: {
    Tab,
    NoData,
    Block
  },
  mounted () {
    window.addEventListener('resize', _.debounce(this.watchView, 200))
  },
  beforeDestroy() {
    window.removeEventListener('resize', _.debounce(this.watchView, 200))
  }
}
</script>

<style lang="scss" scoped>
.iv-home-tab {
  background: #fff;
  border-radius: 16px;
  border-radius: 8px;
  // padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: row;
  .col {
    flex: 1;
    &:first-child {
      margin-left: unset;
    }
    margin-left: 24px;
  }
  .iv-home-tab-center {
    width: 100%;
    overflow-x: hidden;
    padding-top: 24px;
    .iv-home-tab-center-li {
      position: relative;
      .iv-home-tab-center-main {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        width: 480px;
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
      }
      .iv-home-tab-center-time {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  /*活动*/
  .iv-home-tab-activity {
    display: flex;
    align-items: center;
    gap: 17px;
    .iv-home-tab-center-li {
      .iv-home-tab-center-dl {
        width: 218px;
        height: 232px;
        // background: #F5F7FA;
        border-radius: 8px;
        // margin-right: 20px;
        .iv-home-tab-center-dl-img {
          height: 126px;
          position: relative;
          // border-top-left-radius: 8px;
          // border-top-right-radius: 8px;
          img {
            width: 100%;
            height: 100%;
            // border-top-left-radius: 8px;
            // border-top-right-radius: 8px;
          }
          .iv-activity-content-item-img-zhiding {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 48px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            color: #fff;
            background: #FF8F82;
            // border-top-left-radius: 8px;
            // border-bottom-right-radius: 8px;
          }
          .iv-home-tab-center-dl-img-tag {
            width: 155px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
          }
          .iv-home-tag-pink {
            background: url('~@/assets/images/index/pink.png') no-repeat;
            background-size: 100% 100%;
          }
          .iv-home-tag-orange {
            background: url('~@/assets/images/index/orange.png') no-repeat;
            background-size: 100% 100%;
          }
          .iv-home-tag-transparent {
            background: url('~@/assets/images/index/transparent.png') no-repeat;
            background-size: 100% 100%;
          }
        }
        .iv-home-tab-center-dl-text {
          padding: 16px 0px 0px;
          .application-name {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            // height: 44px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap
          }
          .publish-org__name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap
          }
        }
      }
    }
  }
}
</style>
