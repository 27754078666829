<template>
  <div class="iv-block">
    <header class="iv-block-title">
      <Iconfont :type="type" v-if="type" :style="{fontSize: size + 'px'}"></Iconfont>
      <p class="iv-block-title-strip" v-else></p>
      <span class="iv-fs-16 iv-fc-85 iv-fw-b" :class="`iv-m-l-${type ? 32 : 20}`" style="width: 150px;display: inline-block;">{{title}}</span>
      <div class="iv-block-title-right">
        <span class="iv-pointer iv-left iv-m-r-12" :class="flg ? 'click' : ''" @click="dinaji" style="marginLeft:68px;">点击榜</span>
        <span class="iv-pointer iv-right" @click="toMorePage" :class="!flg ? 'click' : ''">回复榜</span>
      </div>
    </header>
    <slot></slot>
  </div>
</template>

<script>
import Iconfont from '@/components/iconfont'
import Tab from '@/components/tab'

export default {
  data () {
    return {
      flg: true
    }
  },
  props: {
    type: { //icon图标
      type: String,
      default: ''
    },
    size: { //icon大小  font-size  因为图标大小是不标准的
      type: String,
      default: '28'
    },
    title: {
      type: String,
      default: '自主功能入口'
    },
    moreText: {
      type: String,
      default: '更多 >'
    }
  },
  created () {
  },
  methods: {
    toMorePage () {
      this.flg = false
      this.$emit('on-reply')
    },
    dinaji () {
      this.flg = true
      this.$emit('on-click')
    }
  },
  components: {
    Iconfont,
    Tab
  }
}
</script>

<style lang="scss" scoped>
.iv-left {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 14px !important;
  height: 39px;
  display: inline-block;
  line-height: 20px;
}
.iv-right {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 14px !important;
  height: 39px;
  display: inline-block;
  line-height: 20px;
}
.click {
  color: #000 !important;
  border-bottom: 2px solid #FFC757 !important;
  font-weight: 600;
}
.iv-block {
  padding: 0 20px;
  background: #fff;
  border-radius: 16px;
  .iv-block-title {
    margin-top: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    height: 41px;
    .iconfont {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
    .iv-block-title-strip {
      position: absolute;
      top: 31%;
      transform: translateY(-50%);
      left: 0;
      width: 6px;
      height: 20px;
      background: #009AD8;
      border-radius: 3px;
    }
    .iv-block-title-right {
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 0;
    }
  }
}
</style>
