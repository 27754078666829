<template>
  <Block title="心灵信箱" class="iv-m-t-24" style="width: 384px" @on-reply="onreply" @on-click="onclick">
    <ul class="iv-heart-mailbox iv-p-b-28 iv-p-t-12">
      <li class="iv-heart-mailbox-li iv-m-t-16 iv-pointer" v-for="(item, index) in list" :key="index" v-if="index < 6">
        <Badge>
          <p>
            <template v-if="index + 1 == 1">
              <span class="iv-title-one"  >{{item.sort}}</span>
              <span class="iv-fs-16 iv-fc-100 iv-m-l-20 iv-hover-color" @click="gonews(item.url)" :title="item.titleWhole">{{item.title}}</span>
            </template>
            <template v-else-if="index + 1 == 2" >
              <span class="iv-title-two" >{{item.sort}}</span>
              <span class="iv-fs-16 iv-fc-85 iv-m-l-20 iv-hover-color" @click="gonews(item.url)" :title="item.titleWhole">{{item.title}}</span>
            </template>
            <template  v-else-if="index + 1 == 3">
              <span class="iv-title-three">{{item.sort}}</span>
              <span class="iv-fs-16 iv-fc-85 iv-m-l-20 iv-hover-color" @click="gonews(item.url)" :title="item.titleWhole">{{item.title}}</span>
            </template>
            <template v-else>
              <span class="iv-title-four" >{{item.sort}}</span>
              <span class="iv-fs-16 iv-fc-85 iv-m-l-20 iv-hover-color" @click="gonews(item.url)" :title="item.titleWhole">{{item.title}}</span>
            </template>
          </p>
        </Badge>
      </li>
    </ul>
    <!-- <img class="iv-heart-img" src="@/assets/images/index/system-maintenance.png" alt=""> -->
  </Block>
</template>

<script>
import Block from './block'

export default {
  name: 'heartMailBox',
  data () {
    return {
      list: [],
      point: [],
      answer: [],
      one: "background: url('~@/assets/images/header/1.png') no-repeat center center"
    }
  },
  created () {
    // this.list = [{
    //   title: '如饥似渴学习 一刻不停提高'
    // }, {
    //   title: '弘扬辛亥革命先驱的崇高精神 共襄中华民族复兴伟业'
    // }, {
    //   title: '贯彻新发展理念 促进产业协调发展'
    // }, {
    //   title: '为实现中华民族伟大复兴提供坚实物质基础'
    // }, {
    //   title: '最广泛最真实最管用的社会主义民主（思想纵横）'
    // }, {
    //   title: '如饥似渴学习 一刻不停提高'
    // }]
    this.oneclick()
    this.onereply()
  },
  methods: {
    //点击图片
    // toMorePage () {
    //   window.open("https://boehome.boe.com/")
    // }
    //回复榜
    onereply () {
      this.$ajax({
        url: 'api-uum/module/portal/mailbox/getReplyRanking',
        method: 'get',
        data: {}
      }).then(({data}) => {
        this.answer = data.data.data
      })
    },
    //回复事件
    onreply () {
      this.list = this.answer
    },
    //点击榜
    oneclick () {
      this.$ajax({
        url: 'api-uum/module/portal/mailbox/getClickRanking',
        method: 'get',
        data: {}
      }).then(({data}) => {
        this.point = data.data.data
        this.list = data.data.data
      })
    },
    //点击事件
    onclick () {
      this.list = this.point
    },
    //单个点击
    gonews (url) {
      this.$ajax({
        url: 'api-uum/module/portal/mailbox/verifyLogin',
        method: 'get',
        data: {
        },
        preventMessage: true
      }).then(({data}) => {
        if (data.code == -1) {
          // this.$Message.error(data.data.message)
          let urls = data.data.info
          window.open(urls)
        } else {
          let urls = `${url}&token=${data.data.token}`
          window.open(urls)
        }
      }).catch(({data}) => {
        let urls = data.data.info
        window.open(urls)
      })
    }
  },
  components: {
    Block
  }
}
</script>

<style lang="scss" scoped>
.iv-title-one {
  background: url('~@/assets/images/header/1.png') no-repeat center center;
  display: inline-block;
  width: 25px;
  height: 23px;
  text-align: center;
  line-height: 25px;
  font-weight: 600;
}
.iv-title-two {
  background: url('~@/assets/images/header/2.png') no-repeat center center;
  display: inline-block;
  width: 25px;
  height: 23px;
  text-align: center;
  line-height: 25px;
  font-weight: 600;
}
.iv-title-three {
  background: url('~@/assets/images/header/3.png') no-repeat center center;
  display: inline-block;
  width: 25px;
  height: 23px;
  text-align: center;
  line-height: 25px;
  font-weight: 600;
}
.iv-title-four {
  background: url('~@/assets/images/header/4.png') no-repeat center center;
  display: inline-block;
  width: 25px;
  height: 23px;
  text-align: center;
  line-height: 25px;
  font-weight: 600;
}
.iv-font-weight {
  font-weight: 600;
  cursor: pointer;
}
.iv-heart-img {
  margin-top: 24px;
  width: 100%;
  height: 100%;
}
.iv-heart-mailbox {
  .iv-heart-mailbox-li {
    /deep/ .ivu-badge-dot {
      top: 50%;
      margin-top: -4px;
      left: 0;
      background: #FFC757;
    }
    p {
      width: 326px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
